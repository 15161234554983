<template>
  <div class="d-flex flex-nowrap">
    <div id="sidebar border-right">
      <Sidebar />
    </div>

    <div class="container mx-5">
      <h2 class="mt-5 px-3 py-4">Banner verwalten</h2>

      <div
        v-if="
          ($store.getters.hasManagerRights &&
            this.selectedOrganisation != '') ||
          !$store.getters.hasManagerRights
        "
        class="containerBackground px-4 py-3"
      >
        <img
          @click="$bvModal.show('createBannerModal')"
          src="../assets/add.png"
          class="icon cursorpointer"
        />
        <p class="d-inline-block ml-3">Neues Banner anlegen</p>
      </div>

      <div
        v-if="
          $store.getters.hasManagerRights && this.selectedOrganisation === ''
        "
        class="seablueContainer px-3 py-4 mt-3"
      >
        <p class="text-white">
          Es muss eine Organisation/ Firma ausgewählt sein, um die Banner sehen
          zu können.
        </p>
      </div>

      <div v-if="showMoonLoader()" class="mx-auto mt-5 loader">
        <moon-loader :size="bigMoonLoaderSize"></moon-loader>
      </div>

      <div
        v-else
        v-for="(banner, index) in banners"
        :key="index"
        class="mt-5 px-4 py-3"
      >
        <b-row align-h="between" class="mb-0 pb-0">
          <b-col cols="3" class="mb-0 p-0">
            <h5
              class="
                greenContainer
                text-white text-center
                fw600
                rounded-heading
                py-2
              "
              :class="{
                errorBackground: banner.state === 'invalid',
                greyContainer:
                  banner.releaseState === 'released' &&
                  banner.activeState === 'deactivated',
              }"
            >
              Banner: {{ truncateString(banner.sizeType) }}
            </h5>
          </b-col>
          <b-col cols="4" class="d-flex justify-content-end pr-0">
            <div v-if="$store.getters.hasManagerRights">
              <div v-if="isReadyForActivation(banner)" class="d-inline-block">
                <p class="d-inline-block greyText pr-2">deaktivieren</p>
                <img
                  src="../assets/deactivate.png"
                  alt="icon-small deactivate"
                  class="icon-small d-inline-block cursorpointer pr-4"
                  @click="showModal('adcellActivateBannerModal', banner)"
                />
              </div>
              <div v-if="!isReadyForActivation(banner)" class="d-inline-block">
                <p class="d-inline-block greyText pr-2">aktivieren</p>
                <img
                  src="../assets/activate.png"
                  alt="icon-small activate"
                  class="icon-small d-inline-block cursorpointer pr-4"
                  @click="showModal('adcellActivateBannerModal', banner)"
                />
              </div>
            </div>
            <div class="d-inline-block pr-3">
              <p class="d-inline-block greyText pr-2">editieren</p>
              <img
                src="../assets/edit.png"
                alt="icon-small edit"
                class="icon-small d-inline-block cursorpointer"
                @click="showModal('editBannerModal', banner)"
              />
            </div>
            <div class="d-inline-block">
              <p class="d-inline-block greyText pr-2">löschen</p>
              <img
                src="../assets/red_delete.png"
                alt="icon-small delete"
                class="icon-small d-inline-block cursorpointer"
                @click="showModal('deleteBannerModal', banner)"
              />
            </div>
          </b-col>
        </b-row>

        <b-row
          class="containerBackground px-3 py-4"
          :class="{
            'red-border': banner.state === 'invalid',
            'green-border':
              banner.releaseState === 'released' &&
              banner.activeState === 'activated',
            'grey-border':
              banner.releaseState === 'released' &&
              banner.activeState === 'deactivated',
          }"
        >
          <b-col cols="1">
            <img
              :src="banner.imageUrl"
              class="d-block mx-auto preview cursorpointer"
              @error="replaceByDefault($event, banner)"
              @click="showBanner"
            />
          </b-col>

          <b-col cols="8" class="pl-4">
            <p class="word-wrap greyText">
              <span class="fw600 text-body"> URL </span>
              {{ banners[index].imageUrl }}
            </p>
            <p class="word-wrap greyText">
              <span class="fw600 text-body"> Link </span>
              {{ banners[index].link }}
            </p>
          </b-col>

          <b-col
            cols="3"
            class="d-flex align-items-center justify-content-between"
          >
            <State :state="showBannerState(banner)"></State>
            <b-button
              v-if="
                $store.getters.hasManagerRights &&
                isReadyForReleaseButton(banner)
              "
              class="d-inline-block buttonDark f22 w-75"
              @click="sendToAdcell(banner)"
              >Freigeben</b-button
            >
          </b-col>
        </b-row>

        <b-row
          v-if="banner.validationErrors !== null"
          class="errorBackground px-3 py-2 mb-3"
        >
          <div
            v-for="(errorMessage, index) in banner.validationErrors"
            :key="index"
          >
            <p class="text-white">{{ errorMessage }}</p>
          </div>
        </b-row>
      </div>

      <div class="mt-3 mb-5">
        <b-pagination-nav
          v-if="$store.state.totalPages > 1"
          v-model="currentPage"
          :number-of-pages="$store.state.totalPages"
          first-number
          last-number
          base-url="#"
          v-on:page-click="changePage($event)"
          align="center"
          class="ml-auto mr-auto"
        />
      </div>
    </div>

    <b-modal
      id="createBannerModal"
      title="Banner anlegen"
      hide-footer
      class="p-3"
      @hide="hideModal('createBannerModal')"
    >
      <b-form-select
        v-if="$store.getters.hasManagerRights"
        v-model="selectedBannerOrganisation"
        :options="$store.state.organisations"
        class="banner-dropdown f18 mb-3"
      >
      </b-form-select>
      <p
        v-if="showErrors && selectedOrganisationMissing()"
        class="error-message f20 pt-0 pb-2"
      >
        Bitte eine Organisation wählen
      </p>

      <p class="f22">Bannerformat</p>
      <b-form-select
        v-model="selectedBanner.sizeType"
        text="Format wählen"
        class="f18 mb-3 w-100 banner-dropdown"
        :options="bannerSizeOptions"
      ></b-form-select>

      <p class="f22">
        URL <span class="greyText">(wo befindet sich die JPG-Datei)</span>
      </p>
      <b-form-textarea
        v-model="selectedBanner.imageUrl"
        placeholder="z.B. http://www.firma.de/banner/b300x600.jpg"
        onfocus="this.placeholder = ''"
        rows="2"
        max-rows="4"
        no-auto-shrink
        class="f18 fw300 mb-3 w-100 url-input"
      >
      </b-form-textarea>
      <p
        v-if="showErrors && imageUrlMissing(selectedBanner)"
        class="error-message f20 pt-0 pb-2"
      >
        Bitte eine Url eintragen
      </p>
      <p
        v-if="showErrors && !urlIsValid(selectedBanner)"
        class="error-message f20 pt-0 pb-2"
      >
        Url kann nicht gelesen werden
      </p>
      <p class="f22">
        Link <span class="greyText">(wo soll das Banner hinleiten)</span>
      </p>
      <b-form-textarea
        v-model="selectedBanner.link"
        placeholder="z.B. http://www.firma.de/tolleprodukte.html "
        onfocus="this.placeholder = ''"
        class="f18 fw300 mb-3 w-100 link-input"
      >
      </b-form-textarea>
      <p
        v-if="showErrors && bannerLinkMissing(selectedBanner)"
        class="error-message f20 pt-0 pb-2"
      >
        Bitte einen Link eintragen
      </p>

      <b-row class="py-2">
        <b-col>
          <p class="fw400 pb-2">Vorschau</p>
          <img
            class="preview"
            :src="selectedBanner.imageUrl"
            alt="kein Fehler"
            @error="replaceByDefault($event, selectedBanner)"
            @click="showBanner"
          />
        </b-col>
      </b-row>

      <b-button class="buttonDark f22 w-100 py-2 mt-3 mb-4" @click="setBanner()"
        >anlegen</b-button
      >
    </b-modal>

    <b-modal
      id="editBannerModal"
      title="Banner editieren"
      hide-footer
      class="p-3"
      @hide="hideModal('editBannerModal')"
    >
      <b-form-select
        v-if="$store.getters.hasManagerRights"
        v-model="selectedBannerOrganisation"
        :options="$store.state.organisations"
        class="banner-dropdown f18 mb-3"
      >
      </b-form-select>
      <p
        v-if="showErrors && selectedOrganisationMissing()"
        class="error-message f20 pt-0 pb-2"
      >
        Bitte eine Organisation wählen
      </p>

      <p class="f22">Bannerformat</p>
      <b-form-select
        v-model="selectedBanner.sizeType"
        text="Format wählen"
        class="f18 mb-3 w-100 banner-dropdown"
        :options="bannerSizeOptions"
      ></b-form-select>

      <p class="f22">
        URL <span class="greyText">(wo befindet sich die JPG-Datei)</span>
      </p>
      <b-form-textarea
        v-model="selectedBanner.imageUrl"
        rows="2"
        max-rows="4"
        no-auto-shrink
        class="f18 fw300 mb-3 w-100 url-input"
      >
      </b-form-textarea>
      <p
        v-if="showErrors && imageUrlMissing(selectedBanner)"
        class="error-message f20 pt-0 pb-2"
      >
        Bitte eine Url eintragen
      </p>
      <p
        v-if="showErrors && !urlIsValid(selectedBanner)"
        class="error-message f20 pt-0 pb-2"
      >
        Url kann nicht gelesen werden.
      </p>

      <p class="f22">
        Link <span class="greyText">(wo soll das Banner hinleiten)</span>
      </p>
      <b-form-textarea
        v-model="selectedBanner.link"
        rows="2"
        max-rows="4"
        no-auto-shrink
        class="f18 fw300 mb-3 w-100 link-input"
      >
      </b-form-textarea>
      <p
        v-if="showErrors && bannerLinkMissing(selectedBanner)"
        class="error-message f20 pt-0 pb-2"
      >
        Bitte eine Url eintragen
      </p>

      <b-row class="py-2">
        <b-col>
          <p class="fw400 pb-2">Vorschau</p>
          <img
            class="preview"
            :src="selectedBanner.imageUrl"
            alt="kein Fehler"
            @error="replaceByDefault($event, selectedBanner)"
            @click="showBanner"
          />
        </b-col>
      </b-row>

      <b-button
        class="buttonDark f22 w-100 py-2 mt-3 mb-4"
        @click="patchBanner(selectedBanner)"
        >speichern</b-button
      >
    </b-modal>

    <b-modal id="previewModal" hide-footer>
      <div class="text-center">
        <img id="bigPreview" :src="bigPreviewSrc" class="mw-100 mh-100" />
      </div>
    </b-modal>

    <b-modal
      id="deleteBannerModal"
      title="Banner wirklich löschen?"
      hide-footer
    >
      <p class="f18 fw600 pl-4 pb-1">Änderung für Organisation/Firma:</p>
      <p class="greenText f24 fw400 pl-4 pb-1">
        {{ companyName }}
      </p>

      <b-row class="text-center w-100 pt-4 ml-2 pr-3">
        <b-col cols="6">
          <b-button
            class="buttonDark w-100"
            @click="deleteBanner(selectedBanner)"
            >ja</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button
            class="buttonDark w-100"
            @click="hideModal('deleteBannerModal')"
          >
            nein
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="adcellActivateBannerModal"
      title="Banner wirklich deaktivieren/aktivieren?"
      hide-footer
    >
      <p class="f18 fw600 pl-4 pb-1">Änderung für Organisation/Firma:</p>
      <p class="greenText f24 fw400 pl-4 pb-1">
        {{ companyName }}
      </p>

      <b-row class="text-center w-100 pt-4 ml-2 pr-3">
        <b-col cols="6">
          <b-button
            class="buttonDark w-100"
            @click="activateBannerOnAdcell(selectedBanner)"
            >ja</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button
            class="buttonDark w-100"
            @click="hideModal('adcellActivateBannerModal')"
          >
            nein
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import State from "@/components/State.vue";
import BannerRequests from "@/api/bannerRequests";
import defaultImg from "@/assets/error.png";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import { organisationEventBus } from "../main";

const TIMER = 3000;
const ERROR_MESSAGE_INVALID_PREVIEW = "Url kann nicht gelesen werden.";

export default {
  components: {
    Sidebar,
    State,
    MoonLoader,
  },
  data() {
    return {
      bigMoonLoaderSize: "150px",
      littleMoonLoaderSize: "40px",
      selectedOrganisation: "",
      companyName: "",
      preview: false,
      bannerSizeOptions: [
        { value: "size_120x600", text: "120x600" },
        { value: "size_160x600", text: "160x600" },
        { value: "size_234x60", text: "234x60" },
        { value: "size_300x50", text: "300x50" },
        { value: "size_300x250", text: "300x250" },
        { value: "size_320x50", text: "320x50" },
        { value: "size_468x60", text: "468x60" },
        { value: "size_728x90", text: "728x90" },
        { value: "size_88x31", text: "88x31" },
        { value: "size_120x60", text: "120x60" },
      ],
      bigPreviewSrc: null,
      windowInterval: null,
      bannerInProcessing: false,
      selectedBannerOrganisation: "",
      selectedBanner: {
        sizeType: "size_120x600",
        imageUrl: "",
        link: "",
        activeState: "inactive",
      },
      showErrors: false,
      currentPage: 1,
      bannerState: "",
    };
  },
  methods: {
    getBanners(organisation, isNewCompany) {
      clearInterval(this.windowInterval);
      this.windowInterval = null;
      this.selectedOrganisation = organisation;
      if (this.$store.getters.hasManagerRights) {
        BannerRequests.getBanners(
          this.selectedOrganisation,
          this.currentPage,
          isNewCompany
        );
      } else if (this.$store.getters.getActiveCompanyID !== "") {
        BannerRequests.getBanners(
          this.$store.getters.getActiveCompanyID,
          this.currentPage,
          true
        );
      }
    },
    setBanner() {
      if (this.checkInputs(this.selectedBanner)) {
        let id = this.$store.getters.getActiveCompanyID;
        if (this.$store.getters.hasManagerRights) {
          id = this.selectedBannerOrganisation;
        }
        BannerRequests.setBanner(this.selectedBanner, id, this.getBanners);
        this.$bvModal.hide("createBannerModal");
        this.getBanners(this.selectedOrganisation, false);
      } else {
        this.showErrors = true;
      }
    },
    patchBanner(banner) {
      if (this.checkInputs(banner)) {
        let id = null;
        if (this.$store.getters.hasManagerRights) {
          id = this.selectedBannerOrganisation;
        }
        BannerRequests.patchBanner(banner, id, this.getBanners);
        this.hideModal("editBannerModal");
        this.getBanners(this.selectedOrganisation, false);
      } else {
        this.showErrors = true;
      }
    },
    deleteBanner(banner) {
      BannerRequests.deleteBanner(banner.id, this.getBanners);
      let bannerIndex = Object.keys(this.$store.state.banners).findIndex(
        (index) => this.$store.state.banners[index]._jv.id === banner._jv.id
      );
      this.$delete(this.$store.state.banners, bannerIndex);
      this.hideModal("deleteBannerModal");
    },
    showBanner(event) {
      let imgSrc = event.target.src;
      var srcStartpart = imgSrc.substring(0, 4);

      if (srcStartpart !== "data") {
        this.bigPreviewSrc = imgSrc;
        this.$bvModal.show("previewModal");
      }
    },
    checkBannersState(banners) {
      if (banners !== null) {
        banners.forEach((element) => {
          if (element.state === "validating") {
            if (this.windowInterval === null) {
              this.bannerUpdateLoop();
            }
          } else {
            clearInterval(this.windowInterval);
          }
        });
      }
    },
    showBannerState(banner) {
      if (
        banner.state === "valid" &&
        (banner.releaseState === "created" || banner.releaseState === "audit")
      ) {
        return "valid";
      } else if (banner.state === "invalid") {
        return "invalid";
      } else if (banner.releaseState === "pending") {
        return "pending";
      } else if (banner.releaseState === "declined") {
        return "declined";
      } else if (
        banner.releaseState === "released" &&
        banner.activeState === "deactivated"
      ) {
        return "deactivated";
      } else if (
        banner.releaseState === "released" &&
        banner.activeState === "activated"
      ) {
        return "activated";
      } else {
        return "invalid";
      }
    },
    bannerUpdateLoop() {
      this.windowInterval = window.setInterval(() => {
        this.getBanners();
      }, TIMER);
    },
    checkInputs(banner) {
      return (
        !this.imageUrlMissing(banner) &&
        !this.bannerLinkMissing(banner) &&
        !this.selectedOrganisationMissing(banner) &&
        this.urlIsValid(banner)
      );
    },
    resetBannerData() {
      this.selectedBanner = {
        sizeType: "size_120x600",
        imageUrl: "",
        link: "",
        activeState: "inactive",
      };
    },
    truncateString(str) {
      return str.substring(5, str.length);
    },
    sendToAdcell(banner) {
      banner.releaseState = "pending";
      BannerRequests.patchBanner(
        banner,
        this.selectedOrganisation,
        this.getBanners
      );
    },
    setCompanyName() {
      const organisation = this.$store.state.organisations.find(
        (element) => element.value === this.selectedOrganisation
      );
      if (organisation != null && organisation != undefined) {
        this.companyName = organisation.text;
      } else {
        this.companyName = "";
      }
    },
    activateBannerOnAdcell(banner) {
      if (banner.activeState === "inactive") {
        banner.activeState = "active";
      } else {
        banner.activeState = "inactive";
      }
      this.patchBanner(banner);
      this.hideModal("adcellActivateBannerModal");
    },
    showModal(modalName, banner) {
      this.selectedBannerOrganisation = banner.company._jv.id;
      this.selectedBanner = JSON.parse(JSON.stringify(banner));
      this.$bvModal.show(modalName);
    },
    hideModal(modalName) {
      this.resetBannerData();
      this.showErrors = false;
      this.$bvModal.hide(modalName);
    },
    imageUrlMissing(banner) {
      return banner.imageUrl === "" ? true : false;
    },
    bannerLinkMissing(banner) {
      return banner.link === "" ? true : false;
    },
    selectedOrganisationMissing() {
      if (this.$store.getters.hasManagerRights) {
        return this.selectedBannerOrganisation === "" ? true : false;
      }
    },
    urlIsValid(banner) {
      var image = new Image();
      var url_image = banner.imageUrl;
      image.src = url_image;
      if (ERROR_MESSAGE_INVALID_PREVIEW === banner.validationErrors) {
        banner.validationErrors = null;
      }
      return image.width !== 0 ? true : false;
    },
    replaceByDefault(e, banner) {
      e.target.src = defaultImg;
      banner.validationErrors = ERROR_MESSAGE_INVALID_PREVIEW;
    },
    isReadyForReleaseButton(banner) {
      return banner.releaseState === "audit";
    },
    isReadyForReleaseIcon(banner) {
      return (
        banner.releaseState !== "created" && banner.releaseState !== "audit"
      );
    },
    isReadyForActivation(banner) {
      return (
        banner.releaseState !== "created" && banner.releaseState !== "audit"
      );
    },
    changePage(event) {
      this.currentPage = event.target.innerText;
      window.scrollTo(0, 0);
      this.getBanners(null, true);
      return true;
    },
    showMoonLoader() {
      let result = false;
      if (this.$store.state.banners === null) {
        result = true;
        if (
          this.$store.getters.hasManagerRights &&
          this.selectedOrganisation === ""
        ) {
          result = false;
        }
      }
      return result;
    },
  },
  computed: {
    banners: {
      get() {
        this.checkBannersState(this.$store.state.banners);
        return this.$store.state.banners;
      },
      set() {},
    },
    loginAccount: function () {
      return this.$store.state.loginAccount;
    },
  },
  watch: {
    loginAccount() {
      this.getBanners();
    },
    selectedOrganisation() {
      this.setCompanyName();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.$store.state.totalPages = 1;
      this.currentPage = 1;
      this.getBanners(this.$store.state.globallySelectedOrganisation);
      this.selectedBannerOrganisation = this.$store.state.globallySelectedOrganisation;
    });
    organisationEventBus.$on("changeOrganisation", () => {
      this.$store.state.totalPages = 1;
      this.currentPage = 1;
      this.getBanners(this.$store.state.globallySelectedOrganisation, true);
      this.selectedBannerOrganisation = this.$store.state.globallySelectedOrganisation;
    });
  },
  destroyed() {
    clearInterval(this.windowInterval);
  },
};
</script>

<style>
@import "../css/main.css";
@import "../css/modal.css";

#createBannerModal .modal-body,
#editBannerModal .modal-body {
  padding: 0.5rem 2rem;
}
.banner-dropdown .dropdown-toggle {
  border: none;
}
.banner-dropdown,
.url-input,
.link-input {
  border: 1px solid var(--tipp-color);
  text-align: left;
}
.preview {
  max-width: 80px;
  max-height: 120px;
}
#previewModal___BV_modal_content_ {
  min-height: 70vh;
  min-width: 70vw;
  background-color: var(--preview-color);
}
#previewModal .modal-dialog {
  min-width: 70vw;
  padding: 0;
  top: -100px;
}
#previewModal .modal-body {
  padding: 0;
}
.red-border {
  border: 2px solid var(--error-color);
}
.green-border {
  border: 2px solid var(--green-color);
}
.grey-border {
  border: 2px solid var(--greyBanner-color);
}
</style>
