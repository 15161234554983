<template>
  <div>
    <div id="loginform" class="mx-auto p-5">
      <div v-if="isError" class="errorBackground px-3 py-2 mb-3">
        <p class="text-white">{{ errorMessage }}</p>
      </div>
      <h3 class="greenText pb-2 mb-4">Kennwort setzen</h3>

      <b-form-group label="neues Kennwort">
        <b-form-input
          v-model="passwordFieldTop"
          class="passwordField inputField"
          placeholder="******"
          type="password"
          :state="passwordFieldTopState"
        ></b-form-input>
      </b-form-group>

      <p id="pwTipp" class="pb-2">
        Mind. 8 Zeichen, 1 x Kleinbuchstabe, 1 x Großbuchstabe, 1 x Ziffer
      </p>

      <b-form-group class="pb-3" label="neues Kennwort wiederholen">
        <b-form-input
          v-model="passwortFieldBot"
          placeholder="******"
          type="password"
          :state="passwortFieldBotState"
          class="passwordField inputField"
        ></b-form-input>
      </b-form-group>

      <b-button class="buttonDark w-100 mt-4" @click="sendNewPassword()"
        >bestätigen</b-button
      >
    </div>
  </div>
</template>

<script>
import PasswordValidator from "@/helpers/passwordValidator";
import UserRequests from "@/api/userRequests";

export default {
  data() {
    return {
      passwordFieldTop: "",
      passwortFieldBot: "",
      passwordFieldTopState: false,
      passwortFieldBotState: false,
      token: "",
      errorMessage: "",
      isError: false,
    };
  },
  methods: {
    validPW(toCheck) {
      if (PasswordValidator.isPasswordSave(toCheck)) {
        this.passwordFieldTopState = true;
      } else {
        this.passwordFieldTopState = false;
      }
    },
    identicalPasswort(toCheck, comparativeValues) {
      if (toCheck === comparativeValues) {
        this.passwortFieldBotState = true;
      } else {
        this.passwortFieldBotState = false;
      }
    },
    sendNewPassword() {
      if (this.passwortFieldBotState && this.passwordFieldTopState) {
        UserRequests.setNewPassword(
          this.token,
          this.passwordFieldTop,
          this.sendNewPasswordSuccess,
          this.sendNewPasswordFailure
        );
        this.isError = false;
      }
    },
    sendNewPasswordSuccess() {
      this.$router.push({
        name: "Login",
      });
    },
    sendNewPasswordFailure(errorMessage) {
      this.passwordFieldTop = "";
      this.passwortFieldBot = "";
      this.errorMessage = errorMessage;
      this.isError = true;
    },
  },
  watch: {
    passwordFieldTop: function (changedPassword) {
      this.passwortFieldBot = "";
      this.validPW(changedPassword);
      this.identicalPasswort(this.passwordFieldBot, this.passwordFieldTop);
    },
    passwortFieldBot: function (changedPassword) {
      this.identicalPasswort(changedPassword, this.passwordFieldTop);
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.token = this.$router.history.current.params.pathMatch;
    });
  },
};
</script>

<style>
@import "../css/login.css";

.is-valid {
  color: white;
  background-color: var(--password-color);
}
.is-not-valid {
  color: white;
  background-color: var(--error-color);
}
.passwordField {
  color: black;
  background-color: white;
}
#pwTipp {
  font-size: 80%;
  color: var(--tipp-color);
}
.form-group {
  margin-bottom: 0;
}
</style>