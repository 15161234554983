export default {
    getArrFromObj(data) {
        let arr = []
        for (let key in data) {
            if (typeof (data[key]) === 'object') {
                try {
                    if (key != "_jv") {
                        arr.push(JSON.parse(JSON.stringify(data[key])));
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }
        return arr;
    }
}