<template>
  <div class="d-flex flex-nowrap">
    <div id="sidebar border-right">
      <Sidebar />
    </div>

    <div class="container mx-5 main-container">
      <b-row class="mt-5">
        <b-col class="containerBackground p-2">
          <b-row align-v="center">
            <b-col cols="2">
              <img
                src="../assets/add.png"
                alt="add button"
                @click="saveAccountData('', 'addAccountModal')"
                class="icon cursorpointer"
              />
            </b-col>
            <b-col cols="10" class="pl-4">
              <p>Account anlegen</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="containerBackground ml-4 p-2">
          <b-row align-v="center">
            <b-col cols="2">
              <img
                src="../assets/add.png"
                alt="d"
                @click="saveAccountData('', 'addOrganisationModal')"
                class="icon cursorpointer"
              />
            </b-col>
            <b-col class="pl-4">
              <p>Organisation/Firma hinzufügen</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="containerBackground ml-4 p-2">
          <b-row align-v="center">
            <b-col cols="2">
              <img
                src="../assets/edit.png"
                alt="d"
                @click="saveAccountData('', 'editOrganisationModal')"
                class="icon cursorpointer"
              />
            </b-col>
            <b-col class="pl-4">
              <p>Organisation/Firma editieren</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="pt-4" align-v="end">
        <b-col class="text-left pl-0">
          <h2 class="mb-0">Übersicht aller Accounts</h2>
        </b-col>
        <b-col class="pl-5 pr-0 mr-0 text-right">
          <b-form-select
            v-model="activeSortMode"
            :options="sortOptions"
            id="sortdropdown"
            class="whiteDropdown"
          >
          </b-form-select>
        </b-col>
      </b-row>
      <div v-if="$store.state.accounts === ''" class="mx-auto mt-5 loader">
        <moon-loader :size="bigMoonLoaderSize"></moon-loader>
      </div>
      <div v-else>
        <b-row class="text-left pt-5 pb-3 fw300">
          <b-col>
            <p>Name, E-Mail, Organisation/Firma</p>
          </b-col>
          <b-col cols="2">
            <p>Rolle</p>
          </b-col>
          <b-col cols="1" class="text-center">
            <p>Edit.</p>
          </b-col>
          <b-col cols="1" class="text-center">
            <p>Löschen</p>
          </b-col>
        </b-row>

        <div v-for="(account, index) in sortedAccounts" :key="index">
          <b-row
            class="text-left containerBackground mb-3 py-3"
            align-v="center"
          >
            <b-col>
              <p>
                {{ account.name }}
                <span class="greyText">({{ account.email }})</span>
              </p>
              <p class="greenText fw600">{{ account.company.name }}</p>
            </b-col>
            <b-col cols="2">
              <p class="fw600">{{ lookUpTableRole(account.role) }}</p>
            </b-col>
            <b-col cols="1" class="text-center">
              <img
                src="../assets/edit.png"
                alt="edit button"
                @click="saveAccountData(account, 'editAccountModal')"
                class="icon cursorpointer"
              />
            </b-col>
            <b-col cols="1" class="text-center">
              <img
                src="../assets/delete.png"
                alt="delete button"
                @click="saveAccountData(account, 'deleteAccountModal')"
                class="icon cursorpointer"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <b-modal
        id="addAccountModal"
        title="Account anlegen"
        hide-footer
        @hide="resetData()"
      >
        <b-row>
          <b-col>
            <p>Name</p>
            <b-form-input
              class="fw600"
              placeholder="max mustermann"
              v-model="activeAccount.name"
            ></b-form-input>
          </b-col>
          <b-col>
            <p>E-Mail-Adresse</p>
            <b-form-input
              class="fw600"
              placeholder="max-mustermann@example.com"
              v-model="activeAccount.email"
              type="email"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="pt-3">Organisation/Firma wählen</p>
            <b-form-select
              v-model="activeAccount.organisation"
              :options="$store.state.organisations"
              :disabled="
                activeAccount.role == 'manager' || activeAccount.role == 'admin'
              "
            ></b-form-select>
          </b-col>
          <b-col>
            <p class="pt-3">Rolle wählen</p>
            <b-form-select
              v-model="activeAccount.role"
              :options="roleOptions"
              @change="checkOrganisation()"
            ></b-form-select>
          </b-col>
        </b-row>
        <p class="info-text greyText py-3">
          HINWEIS:<br />Nach dem Anlegen wird automatisch eine Mail für die
          Passwort-Erstellung an die E-Mail-Adresse verschickt.
        </p>
        <b-row>
          <b-col cols="6">
            <b-button class="buttonDark w-100 p-3 f22" @click="addAccount()"
              >anlegen</b-button
            >
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        id="addOrganisationModal"
        title="Organisation/Firma hinzufügen"
        hide-footer
        @hide="resetData()"
      >
        <b-row>
          <b-col>
            <p>Name</p>
            <b-form-input
              class="fw600"
              placeholder="Firmenname"
              v-model="activeOrganisation.name"
            ></b-form-input>
            <p
              v-if="activeOrganisation.name === '' && showErrors"
              class="error-message pt-2 pb-0"
            >
              Name darf nicht leer sein
            </p>
          </b-col>
          <b-col>
            <p>E-Mail-Adresse</p>
            <b-form-input
              class="fw600"
              placeholder="kontakt@example.com"
              v-model="activeOrganisation.email"
              type="email"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="pt-3">Website</p>
            <b-form-input
              class="fw600"
              placeholder="www.firma.de"
              v-model="activeOrganisation.website"
            ></b-form-input>
          </b-col>
          <b-col>
            <p class="pt-3">Telefon-Nr.</p>
            <b-form-input
              class="fw600"
              placeholder="12345"
              v-model="activeOrganisation.phone"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <p class="pt-3">Adcell Programm-ID</p>
            <b-form-input
              class="fw600"
              placeholder="123"
              v-model="activeOrganisation.programID"
              @blur="isProgramIDNumber"
            ></b-form-input>
            <p
              v-if="!programIDIsNumber && showErrors"
              class="error-message pt-2 pb-0"
            >
              Programm-ID muss eine Zahl oder leer sein
            </p>
          </b-col>
          <b-col>
            <p class="pt-3">Google Studio Präfix</p>
            <b-form-input
              class="fw600"
              placeholder="praefix"
              v-model="activeOrganisation.dataStudioPraefix"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col cols="6">
            <b-button
              class="buttonDark w-100 p-3 f22"
              @click="addOrganisation()"
              >speichern</b-button
            >
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        id="editOrganisationModal"
        title="Organisation/Firma editieren"
        hide-footer
        @hide="resetData()"
      >
        <b-row>
          <b-col cols="6" class="pb-3">
            <p>bestehende Organisation/Firma auswählen</p>
            <b-form-select
              class="mx-0"
              v-model="activeOrganisation.organisationID"
              :options="$store.state.organisations"
              @change="pasteCompanyData()"
            ></b-form-select>
            <p
              v-if="showErrors && this.activeOrganisation.organisationID === ''"
              class="error-message f20 pt-0 pb-2"
            >
                    Bitte eine Organisation wählen
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>Name</p>
            <b-form-input
              class="fw600"
              placeholder="Firmenname"
              v-model="activeOrganisation.name"
            ></b-form-input>
            <p
              v-if="activeOrganisation.name === '' && showErrors"
              class="error-message pt-2 pb-0"
            >
              Name darf nicht leer sein
            </p>
          </b-col>
          <b-col>
            <p>E-Mail-Adresse</p>
            <b-form-input
              class="fw600"
              placeholder="kontakt@example.com"
              v-model="activeOrganisation.email"
              type="email"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="pt-3">Website</p>
            <b-form-input
              class="fw600"
              placeholder="www.firma.de"
              v-model="activeOrganisation.website"
            ></b-form-input>
          </b-col>
          <b-col>
            <p class="pt-3">Telefon-Nr.</p>
            <b-form-input
              class="fw600"
              placeholder="12345"
              v-model="activeOrganisation.phone"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <p class="pt-3">Adcell Programm-ID</p>
            <b-form-input
              class="fw600"
              placeholder="123"
              v-model="activeOrganisation.programID"
              @blur="isProgramIDNumber"
            ></b-form-input>
            <p
              v-if="!programIDIsNumber && showErrors"
              class="error-message pt-2 pb-0"
            >
              Programm-ID muss eine Zahl oder leer sein
            </p>
          </b-col>
          <b-col>
            <p class="pt-3">Google Studio Präfix</p>
            <b-form-input
              class="fw600"
              placeholder="praefix"
              v-model="activeOrganisation.dataStudioPraefix"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col cols="6">
            <b-button
              class="buttonDark w-100 p-3 f22"
              @click="editOrganisation()"
              >speichern</b-button
            >
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        id="editAccountModal"
        title="Account editieren"
        hide-footer
        @hide="resetData()"
      >
        <b-row>
          <b-col>
            <p>Name</p>
            <b-form-input
              class="fw600"
              v-model="activeAccount.name"
            ></b-form-input>
          </b-col>
          <b-col>
            <p>E-Mail-Adresse</p>
            <b-form-input
              class="fw600"
              v-model="activeAccount.email"
              type="email"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="pt-3">Organisation/Firma wählen</p>
            <b-form-select
              class="m-0"
              v-model="activeAccount.organisation"
              :options="$store.state.organisations"
              :disabled="
                activeAccount.role == 'manager' || activeAccount.role == 'admin'
              "
            ></b-form-select>
          </b-col>
          <b-col>
            <p class="pt-3">Rolle wählen</p>
            <b-form-select
              class=""
              v-model="activeAccount.role"
              :options="roleOptions"
            ></b-form-select>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col>
            <b-button class="buttonDark w-100 p-3 f22" @click="editAccount()"
              >Änderung speichern</b-button
            >
          </b-col>
          <b-col>
            <b-button
              class="buttonDark w-100 p-3 f22"
              @click="sendMail(activeAccount.email)"
              >E-Mail für Passwort Reset senden</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="info-text greyText pt-3">
              Letztes Login des Nutzers:
              {{ displayLastLogin(activeAccount.lastLogin) }}
            </p>
          </b-col>
          <b-col>
            <p class="info-text pt-3 fw400">
              {{ resetEmailText }}
            </p>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        id="deleteAccountModal"
        hide-footer
        @hide="resetData()"
        title="Account wirklich löschen?"
      >
        <b-row align-v="center">
          <b-col cols="3" class="px-4">
            <img src="../assets/profilimage.png" alt="x" class="pl-3" />
          </b-col>
          <b-col class="pl-3">
            <p class="greenText f24 fw400">{{ this.activeAccount.name }}</p>
            <p class="greenText f18 fw400">
              {{ this.activeAccount.organisation }}
            </p>
          </b-col>
        </b-row>

        <b-row class="text-center w-100 pt-4 ml-2 pr-3">
          <b-col cols="6">
            <b-button class="buttonDark w-100" @click="deleteAccount()"
              >ja</b-button
            >
          </b-col>
          <b-col cols="6">
            <b-button
              class="buttonDark w-100"
              @click="$bvModal.hide('deleteAccountModal')"
              >nein</b-button
            >
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import UserRequests from "@/api/userRequests";
import CompanyRequests from "@/api/companyRequests";
import Sorting from "@/helpers/sorting";
import LookUpTableRoles from "@/helpers/lookUpTableRoles";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  data() {
    return {
      bigMoonLoaderSize: "150px",
      roleOptions: [
        { value: "customer", text: "Klient" },
        { value: "sales", text: "Vertrieb" },
        { value: "manager", text: "Manager" },
        { value: "admin", text: "Admin" },
      ],
      sortOptions: [
        { value: "name", text: "Name" },
        { value: "email", text: "E-Mail" },
        { value: "company", text: "Firma" },
        { value: "role", text: "Rolle" },
      ],
      activeSortMode: "name",
      activeAccount: {
        name: "",
        email: "",
        organisation: "test1",
        role: "customer",
      },
      activeOrganisation: {
        organisationID: "",
        name: "",
        email: "",
        website: "www",
        phone: "",
        programID: null,
      },
      accountInProcessing: false,
      programIDIsNumber: true,
      showErrors: false,
      resetEmailText: "",
    };
  },
  components: {
    Sidebar,
    MoonLoader,
  },
  methods: {
    addAccount() {
      if (this.isNotEmpty(this.activeAccount)) {
        UserRequests.addAccount(this.activeAccount, this.reloadAccounts);
        this.$bvModal.hide("addAccountModal");
      }
    },
    editAccount() {
      if (this.isNotEmpty(this.activeAccount)) {
        UserRequests.patchAccount(this.activeAccount, this.reloadAccounts);
        this.$bvModal.hide("editAccountModal");
      }
    },
    deleteAccount() {
      UserRequests.deleteAccount(this.activeAccount.id, this.reloadAccounts);
      this.$bvModal.hide("deleteAccountModal");
      this.resetAccounts();
    },
    addOrganisation() {
      if (this.programIDIsNumber && this.activeOrganisation.name) {
          CompanyRequests.setCompany(
            this.activeOrganisation,
            this.getCompanies
          );
        this.$bvModal.hide("addOrganisationModal");
      } else {
        this.showErrors = true;
      }
    },
    editOrganisation() {
      if (this.activeOrganisation.organisationID && this.programIDIsNumber && this.activeOrganisation.name) {
          CompanyRequests.patchCompany(this.activeOrganisation);
        this.$bvModal.hide("editOrganisationModal");
      } else {
        this.showErrors = true;
      }
    },
    getCompanies() {
      CompanyRequests.getCompanies();
    },
    getAccounts() {
      UserRequests.getAccounts("", "", "");
    },
    saveAccountData(account, modalName) {
      if (account !== "") {
        this.activeAccount = JSON.parse(JSON.stringify(account));
        if (account.company._jv !== undefined) {
          this.activeAccount["organisation"] = account.company._jv.id;
        }
      }

      this.accountInProcessing = true;
      this.$bvModal.show(modalName);
    },
    resetData() {
      this.showErrors = false;
      (this.programIDIsNumber = true), (this.accountInProcessing = false);
      this.activeAccount = {
        name: "",
        email: "",
        organisation: "test1",
        role: "customer",
      };
      this.activeOrganisation = {
        organisationID: "",
        name: "",
        email: "",
        website: "",
        phone: "",
        programID: null,
      };
      this.resetEmailText = "";
    },
    isNotEmpty(user) {
      let result = true;

      if (user.name === "" || user.email === "" || user.role === "") {
        result = false;
      }

      if (!this.$store.getters.hasManagerRights && user.organisation === "") {
        result = false;
      }

      return result;
    },
    lookUpTableRole(role) {
      return LookUpTableRoles.getRole(role);
    },
    pasteCompanyData() {
      this.showErrors = false;
      let toCopy = this.$store.state.organisations.find(
        (organisation) =>
          organisation.value === this.activeOrganisation.organisationID
      );
      if (toCopy.value !== "") {
        this.activeOrganisation = {
          organisationID: this.activeOrganisation.organisationID,
          name: toCopy.text,
          email: toCopy.data.email,
          website: toCopy.data.website,
          phone: toCopy.data.phone,
          programID: toCopy.data.programID,
          dataStudioPraefix: toCopy.data.dataStudioPraefix,
          _jv: toCopy.data._jv,
        };
      } else {
        this.activeOrganisation = {
          organisationID: "",
          name: "",
          email: "",
          website: "",
          phone: "",
          programID: null,
          dataStudioPraefix: "",
        };
      }
    },
    reloadAccounts() {
      this.resetAccounts();
      this.getAccounts();
    },
    resetAccounts() {
      this.$store.commit("updateAccounts", "");
    },
    checkOrganisation() {
      if (
        this.activeAccount.role == "manager" ||
        this.activeAccount.role == "admin"
      ) {
        this.activeAccount.organisation = "";
      }
    },
    isProgramIDNumber() {
      isNaN(this.activeOrganisation.programID)
        ? (this.programIDIsNumber = false)
        : (this.programIDIsNumber = true);
    },
    displayLastLogin(unixTimeStamp) {
      const dateObject = new Date(unixTimeStamp * 1000);
      return dateObject.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    sendMail(resetEmail) {
      UserRequests.sendPasswordResetMail(
        resetEmail,
        this.sendMailSuccess,
        this.sendMailFailure
      );
    },
    sendMailSuccess() {
      this.resetEmailText = "E-Mail wurde gesendet.";
    },
    sendMailFailure() {
      this.resetEmailText = "Es ist ein Fehler aufgetreten";
    },
  },
  computed: {
    sortedAccounts: function () {
      let result = {};
      if (this.$store.state.accounts.length > 0) {
        result = Sorting.sort(
          this.activeSortMode,
          this.$store.state.accounts,
          false
        );
      }
      return result;
    },
    loginAccount: function () {
      return this.$store.state.loginAccount;
    },
  },
  watch: {
    loginAccount() {
      this.getAccounts();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getAccounts();
    });
  },
};
</script>

<style>
@import "../css/main.css";
@import "../css/modal.css";
@import "../css/dropdowns.css";

#sortdropdown {
  width: 380px;
}
#addAccountModal .modal-content,
#addOrganisationModal .modal-content,
#editOrganisationModal .modal-content,
#editAccountModal .modal-content {
  width: 880px;
  margin-left: 0;
  padding: 16px;
}
#editOrganisationModal___BV_modal_body_ {
  padding-top: 0;
}
#addAccountModal .modal-title,
#addOrganisationModal .modal-title,
#editOrganisationModal .modal-title,
#editAccountModal .modal-title {
  padding-left: 0;
}
#addAccountModal p,
#addOrganisationModal p,
#editOrganisationModal p,
#editAccountModal p {
  font-size: 22px;
  padding-bottom: 5px;
}
.info-text {
  font-size: 18px !important;
}
#addAccountModal input,
#addOrganisationModal input,
#editOrganisationModal input,
#editAccountModal input {
  height: 55px;
}
#addAccountModal select,
#editAccountModal select {
  height: 55px;
}
#addOrganisationModal .modal-header,
#editAccountModal .modal-header {
  padding-bottom: 0;
}
#deleteAccountModal .modal-content {
  width: 400px;
}
#deleteAccountModal .modal-title {
  color: #000000;
  font-weight: 300;
  font-size: 22px;
  padding-left: 1.5rem;
}
#deleteAccountModal button.close {
  padding-right: 30px;
}
</style>
