<template>
  <div class="d-flex flex-nowrap">
    <div id="sidebar border-right">
      <Sidebar />
    </div>

    <div class="container mx-5">
      <b-row class="mt-5 px-3 py-4" align-v="end">
        <h2 class="mb-0">CSV's verwalten</h2>
      </b-row>
      <p class="fw300 mb-4">
        <router-link
          :to="{
            name: $store.getters.isAdmin
              ? 'AdminFileManagement'
              : 'ClientDownloads',
          }"
        >
          <span class="greenText fw600 underline">Hier</span>
        </router-link>
        geht´s zum Infoblatt zur Bereitstellung von Daten.
      </p>

      <div
        v-if="
          $store.getters.hasManagerRights &&
          this.$store.state.globallySelectedOrganisation === ''
        "
        class="seablueContainer px-3 py-4 mt-3"
      >
        <p class="text-white">
          Es muss eine Organisation/ Firma ausgewählt sein, um die CSV-Datei
          sehen zu können.
        </p>
      </div>

      <div v-if="showMoonLoader" class="mx-auto mt-5 loader">
        <moon-loader :size="bigMoonLoaderSize"></moon-loader>
      </div>
      <div v-else-if="csv !== null">
        <div v-if="csv === ''" class="containerBackground px-4 py-3 mb-1">
          <img
            @click="$bvModal.show('createCsvModal')"
            src="../assets/add.png"
            class="icon cursorpointer"
          />
          <p class="d-inline-block ml-3">Neue CSV-Datei verknüpfen</p>
        </div>

        <div v-if="csv !== ''" class="mt-5">
          <b-row align-h="between" class="mb-0 pb-0">
            <b-col cols="3" class="mb-0 p-0">
              <h2
                class="
                  rounded-heading
                  greenContainer
                  text-white text-center
                  f20
                  fw600
                  ml-3
                  py-2
                "
              >
                CSV
              </h2>
            </b-col>
            <b-col
              v-if="$store.getters.hasManagerRights"
              cols="3"
              class="row justify-content-end pr-5"
            >
              <p class="greyText d-inline-block pr-2">löschen</p>
              <img
                src="../assets/red_delete.png"
                alt="icon delete"
                class="d-inline-block pb-2 delete-button-small cursorpointer"
                @click="$bvModal.show('deleteCsvModal')"
              />
            </b-col>
          </b-row>

          <div class="containerBackground p-3">
            <b-row>
              <b-col cols="9">
                <p class="greyText">
                  <span class="fw600 text-dark">URL </span> {{ csv.fileUrl }}
                </p>
                <p class="d-inline-block pr-4">
                  Verknüpft am:
                  {{ displayLastChangeDate(csv.lastFileUrlChange) }}
                </p>
                <p class="d-inline-block">
                  Überprüft am:
                  {{ displayLastValidationDate(csv.lastValidation) }}
                </p>
              </b-col>
              <b-col
                cols="3"
                class="d-flex align-items-center justify-content-between"
              >
                <State :state="showCSVState(csv)"></State>
                <b-button
                  v-if="
                    $store.getters.hasManagerRights &&
                    showCSVState(csv) === 'valid'
                  "
                  @click="sendToAdcell()"
                  class="d-inline-block buttonDark f22 w-75"
                  >Freigeben
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-row
            class="errorBackground px-3 py-2 m-0"
            v-if="csv.validationErrors !== null"
          >
            <div
              v-for="(errorMessage, index) in csv.validationErrors"
              :key="index"
            >
              <p class="text-white">{{ errorMessage }}</p>
            </div>
          </b-row>
          <b-row
            v-if="csv.releaseState === 'declined'"
            class="errorBackground px-3 py-2 m-0"
          >
            <p class="text-white">Die CSV-Datei wurde von Adcell abgelehnt.</p>
          </b-row>
        </div>
        <div
          v-if="!$store.getters.hasManagerRights"
          class="seablueContainer d-flex justify-content-between px-4 py-3 mt-0"
        >
          <p class="text-white f20 fw-400 py-2 d-inline-block">
            Für eine neue CSV-Verknüpfung bitte den Marketingmanager
            kontaktieren.
          </p>
        </div>
      </div>
    </div>

    <b-modal id="createCsvModal" title="CSV verknüpfen" @hide="resetCSVLink()" hide-footer class="p-3">
      <div class="csv-modal-content">
        <b-form-select
          v-if="$store.getters.hasManagerRights"
          v-model="selectedOrganisationSetCSV"
          :options="$store.state.organisations"
          class="banner-dropdown f18 mb-3"
        >
        </b-form-select>
        <p class="f22">
          URL <span class="greyText">(wo befindet sich die CSV-Datei)</span>
        </p>
        <b-form-textarea
          v-model="newCsvLink"
          placeholder="z.B. http://www.firma.de/produkte.csv"
          rows="3"
          max-rows="4"
          class="f18 fw300"
        >
        </b-form-textarea>

        <b-button
          class="buttonDark f22 w-100 py-2 mt-3 mt-5 mb-3"
          @click="setCSV()"
          >verknüpfen</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="deleteCsvModal"
      title="CSV-Verknüpfung wirklich löschen?"
      hide-footer
    >
      <p class="f18 fw600 pl-4 pb-1">Änderung für Organisation/Firma:</p>
      <p class="greenText f24 fw400 pl-4 pb-1">
        {{ companyName }}
      </p>

      <b-row class="text-center w-100 pt-4 ml-2 pr-3">
        <b-col cols="6">
          <b-button class="buttonDark w-100" @click="deleteCsv()">ja</b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            class="buttonDark w-100"
            @click="$bvModal.hide('deleteCsvModal')"
          >
            nein
          </b-button>
        </b-col>
      </b-row>
      <p class="redText f20 fw600 pl-4 pt-3">
        ACHTUNG: Die Löschung bei Adcell muss dort manuell erfolgen.
      </p>
    </b-modal>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import CsvRequests from "@/api/csvRequests";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import State from "@/components/State.vue";
import { organisationEventBus } from "../main";

const TIMER = 3000;

export default {
  components: {
    Sidebar,
    State,
    MoonLoader,
  },
  data() {
    return {
      bigMoonLoaderSize: "150px",
      littleMoonLoaderSize: "40px",
      selectedOrganisation: "",
      windowInterval: null,
      error: true,
      selectedOrganisationSetCSV: "",
      newCsvLink: "",
      companyName: "",
      activeState: "inactive",
    };
  },
  methods: {
    getCsv() {
      if (this.$store.getters.hasManagerRights) {
        CsvRequests.getCSV(
          this.$store.state.globallySelectedOrganisation,
          this.reloadCsv
        );
      } else {
        CsvRequests.getCSV(
          this.$store.getters.getActiveCompanyID,
          this.reloadCsv
        );
      }
    },
    setCSV() {
      if (this.isNotEmpty()) {
        if (this.$store.getters.hasManagerRights) {
          CsvRequests.setCSV(
            this.newCsvLink,
            this.activeState,
            this.selectedOrganisationSetCSV,
            this.reloadCsv
          );
        } else if (this.$store.getters.getActiveCompanyID !== "") {
          CsvRequests.setCSV(
            this.newCsvLink,
            this.$store.getters.getActiveCompanyID,
            this.reloadCsv,
            this.activeState
          );
        }
        this.$bvModal.hide("createCsvModal");
      }
    },
    deleteCsv() {
      CsvRequests.deleteCsv(this.csv.id, this.reloadCsv);
      this.$bvModal.hide("deleteCsvModal");
    },
    checkCsvState(csv) {
      if (csv.state === "validating") {
        if (this.windowInterval === null) {
          this.csvUpdateLoop();
        }
      } else {
        clearInterval(this.windowInterval);
      }
    },
    csvUpdateLoop() {
      this.windowInterval = window.setInterval(() => {
        this.getCsv();
      }, TIMER);
    },
    reloadCsv() {
      this.resetCSV();
      this.getCsv();
    },
    setCompanyName() {
      const organisation = this.$store.state.organisations.find(
        (element) => element.value === this.$store.state.globallySelectedOrganisation
      );
      this.companyName = organisation.text;
    },
    resetCSV() {
      this.$store.commit("updateCsv", null);
    },
    resetCSVLink() {
       this.newCsvLink = '';
    },
    isNotEmpty() {
      let result = true;

      if (this.newCsvLink === "") {
        result = false;
      }

      if (this.$store.getters.hasManagerRights) {
        if (this.selectedOrganisationSetCSV === "") {
          result = false;
        }
      }

      return result;
    },
    sendToAdcell() {
      this.csv.releaseState = "pending";
      CsvRequests.patchCsv(this.csv, this.getCsv);
    },
    displayLastChangeDate(unixTimeStamp) {
      const dateObject = new Date(unixTimeStamp * 1000);
      return dateObject.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    displayLastValidationDate(unixTimeStamp) {
      const dateObject = new Date(unixTimeStamp * 1000);
      const date = dateObject.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const hours = () => {
        return dateObject.getHours() < 10
          ? "0" + dateObject.getHours()
          : dateObject.getHours();
      };
      const minutes = dateObject.getMinutes();
      const dateAndTime = date + " " + hours() + ":" + minutes;
      return dateAndTime;
    },
    showCSVState(csv) {
      if (
        csv.state === "valid" &&
        (csv.releaseState === "created" || csv.releaseState === "audit")
      ) {
        return "valid";
      } else if (csv.state === "invalid") {
        return "invalid";
      } else if (csv.releaseState === "pending") {
        return "pending";
      } else if (csv.releaseState === "declined") {
        return "declined";
      } else if (csv.releaseState === "released") {
        return "activated";
      } else {
        return "invalid";
      }
    },
  },
  computed: {
    csv: {
      get() {
        let csv = this.$store.state.csv;
        if (csv !== null && csv !== "") {
          this.checkCsvState(csv);
        }

        return csv;
      },
      set() {},
    },
    showMoonLoader() {
      return this.csv === null && this.selectedOrganisation !== "";
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getCsv();
      this.selectedOrganisationSetCSV = this.$store.state.globallySelectedOrganisation;
      this.setCompanyName();
    });
    organisationEventBus.$on("changeOrganisation", () => {
      this.getCsv();
      this.selectedOrganisationSetCSV = this.$store.state.globallySelectedOrganisation;
      this.setCompanyName();
    });
  },
  destroyed() {
    clearInterval(this.windowInterval);
  },
};
</script>

<style scoped>
@import "../css/main.css";
@import "../css/modal.css";

.csv-modal-content {
  padding: 0 1rem 0 1rem;
}

#fileSelector {
  background-color: white;
  color: black;
  border: 1px solid var(--tipp-color);
  cursor: pointer;
}
.activeCSVRequest {
  background-color: var(--green-color);
}
</style>
