<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <Header />
    <div id="content">
      <router-view />
    </div>
    <ErrorMessage />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

export default {
  name: "App",
  components: {
    Header,
    ErrorMessage,
  },
  methods: {
    isEligibleForThisPage(sideRoute) {
      let result = false;
      this.$store.getters.getActiveSidebar.forEach((element) => {
        if (element.route === sideRoute) {
          result = true;
        }
      });
      return result;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      let name = this.$router.history.current.name;
      if (
        name !== "Login" &&
        name !== "Policy" &&
        name !== "Imprint" &&
        name !== "LoginPasswordReset"
      ) {
        this.$store.commit("updateHistory", name);
        this.$router.push({ name: "Login" });
      }
    });
  },
  watch: {
    $route(to) {
      let needPermission =
        to.name !== "Imprint" && to.name !== "Policy" && to.name !== "Login";

      if (needPermission && !this.isEligibleForThisPage(to.name)) {
        this.$router.push({
          name: this.$store.getters.getActiveSidebar[0].route,
        });
      }
    },
  },
};
</script>

<style>
@import "css/main.css";
</style>
