<template>
  <div class="d-flex flex-nowrap">
    <div id="sidebar border-right">
      <Sidebar />
    </div>

    <div class="container mx-5">
      <b-row class="mt-5 px-3 py-4" align-v="end">
        <b-col class="text-left pl-0">
          <h2 class="mb-0">Downloads</h2>
        </b-col>
        <b-col
          class="pl-5 pr-0 mr-0 text-right"
          v-if="$store.getters.hasManagerRights"
        >
          <moon-loader v-if="$store.state.organisations === ''"></moon-loader>
          <b-form-select
            v-else
            v-model="selectedOrganisation"
            :options="$store.state.organisations"
            @change="filter()"
            class="whiteDropdown"
          >
          </b-form-select>
        </b-col>
      </b-row>
      <div class="d-flex flex-wrap">
        <div v-if="$store.state.files === ''" class="mx-auto mt-5 loader">
          <moon-loader :size="moonLoaderSize"></moon-loader>
        </div>
        <div
          v-else
          v-for="(file, index) in files"
          :key="index"
          id="download-area"
          class="d-flex flex-column justify-content-between"
        >
          <div
            class="d-flex flex-column justify-content-between h-75 px-3 pt-5"
          >
            <p class="f20 word-wrap">{{ file.name }}</p>
            <div class="d-flex justify-content-between">
              <p class="d-inline-block f20 fw300">{{ file.extension }}</p>
              <p class="d-inline-block f20 fw300">{{ displayFileSize(file.size) }}</p>
            </div>
          </div>
          <img
            :disabled="$store.getters.isFileDisabled(file.id)"
            @click="download(file.id, file.name)"
            src="../assets/download.png"
            class="icon cursorpointer mx-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import FileRequests from "@/api/fileRequests";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  data() {
    return {
      moonLoaderSize: "150px",
      selectedOrganisation: "",
    };
  },
  components: {
    Sidebar,
    MoonLoader,
  },
  methods: {
    getFiles() {
      if (this.$store.getters.hasManagerRights) {
        FileRequests.getFiles("0," + this.selectedOrganisation);
      } else if (this.$store.getters.getActiveCompanyID !== "") {
        FileRequests.getFiles("0," + this.$store.getters.getActiveCompanyID);
      }
    },
    download(fileId, fileName) {
      this.$store.commit("disableFile", fileId);
      FileRequests.downloadRequest(fileId, fileName, this.downloadCallBack);
    },
    downloadCallBack(fileId) {
      this.$store.commit("enableFile", fileId);
    },
    filter() {
      this.$store.commit("updateFiles", "");
      this.getFiles();
    },
    displayFileSize(fileSize) {
      if (fileSize < 1000000) {
        return this.fileSize = Math.round(fileSize / 1000) + ' kB';
      } else {
        return this.fileSize = (fileSize / 1000000).toFixed(1) + ' MB';
      }
    },
  },
  computed: {
    files: function () {
      return this.$store.state.files;
    },
    loginAccount: function () {
      return this.$store.state.loginAccount;
    },
  },
  watch: {
    loginAccount() {
      this.getBanners();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.filter();
    });
  },
};
</script>

<style scoped>
#download-area {
  width: 180px;
  height: 245px;
  background: url(../assets/icon_doc.png) top no-repeat;
  margin: 0 3rem 3rem 0;
}
</style>
