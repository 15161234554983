<template>
  <div id="sidebar-menu" class="vh-100 mt-n-5">
    <div
      v-for="(element, index) in $store.getters.getActiveSidebar"
      :key="index"
      v-bind:id="element.name"
    >
      <router-link :to="{ name: element.route }">
        <b-button class="sidebarElement w-100 py-2 pl-4 text-left">{{
          element.name
        }}</b-button></router-link
      >
    </div>
    <b-row class="d-flex fixed-bottom fix-width">
      <router-link :to="{ name: 'Imprint' }">
        <p class="greenText bottomlinks pl-4 pb-4">Impressum</p>
      </router-link>
      <router-link :to="{ name: 'Policy' }">
        <p class="greenText bottomlinks pl-4 pb-4">Datenschutz</p>
      </router-link>
    </b-row>
  </div>
</template>

<script>
export default {
  methods: {
    activateSideMenuButton() {
      let name = this.getElementName(this.$store.getters.getActiveSidebar);
      let element = this.getElement(name);
      if (element !== null) {
        this.changeClass(element);
      }
    },
    getElementName(elements) {
      let result = null;
      Object.keys(elements).forEach((key) => {
        if (this.$route.name === elements[key].route) {
          result = elements[key].name;
          return;
        }
      });
      return result;
    },
    getElement(name) {
      let element = document.getElementById(name);
      if (element !== undefined && element !== null) {
        return element.firstChild.firstChild;
      }
      return null;
    },
    changeClass(element) {
      element.classList.remove("sidebarElement");
      element.classList.remove("btn-secondary");
      element.classList.add("activeSidebarElement");
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.activateSideMenuButton();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.activateSideMenuButton();
    });
  },
};
</script>

<style>
#sidebar-menu {
  border-right: 2px solid #ededed;
  width: 300px;
}
.sidebarElement {
  border-bottom: 2px solid #ededed;
  height: 70px;
  background-color: white;
  color: black;
  font-weight: 300;
  font-size: 18px;
}
.activeSidebarElement {
  border: 2px solid var(--green-color);
  height: 70px;
  background-color: var(--green-color);
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.activeSidebarElement.btn:hover{
  color: white;
  text-decoration: none;
  cursor: auto;
}

.bottomlinks {
  font-size: 18px;
  text-decoration: underline;
  display: inline-block;
}
.fix-width {
  width: 300px;
}
</style>
