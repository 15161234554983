import Store from '../store';
import ObjToArrConverter from '@/helpers/objectToArrayConverter';
import ApiCallMode from '@/api/apiCallMode';
import UserRequests from '@/api/userRequests';

export default {
    async request(callMode, pathOrObj, callBackFunction, params) {
        try {
            let response = await Store.dispatch('jv/' + ApiCallMode(callMode), [pathOrObj, { params: params }]);
            callBackFunction(response);
        } catch (e) {
            console.log(e);
            if (e.message === "Request failed with status code 401") {
                UserRequests.logout(callBackFunction);
            } else if (e.message === "Request failed with status code 500") {
                Store.state.showErrorMessageCode500 = true;
            }
        }
    },
    deleteJV(obj) {
        return ObjToArrConverter.getArrFromObj(obj);
    },
    deleteRelationship(obj) {
        delete obj._jv.relationships;
    }
};