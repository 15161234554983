import Store from '../store';

const _lookUpTableEN = {
    customer: "Client",
    manager: "Manager",
    sales: "Sales",
    admin: "Admin",
}

const _lookUpTableDE = {
    customer: "Klient",
    manager: "Manager",
    sales: "Vertrieb",
    admin: "Admin",
}

export default {
    getRole(name, lang) {
        return getRoleName(getTable(lang), name);
    },
    translate(name) {
        return getKeyFromTableWithName(getTable(), name);
    }
}

function getRoleName(_lookupTable, name) {
    if (typeof _lookupTable[name] !== 'undefined') {
        return _lookupTable[name];
    }
}

function getTable(lang) {
    let table;
    let language = lang === "" ? Store.getters.getActiveLanguage : lang;
    switch (language) {
        case "de":
            table = _lookUpTableDE;
            break;

        case "en":
            table = _lookUpTableEN;
            break;

        default: table = _lookUpTableDE;
            break;
    }
    return table;
}

function getKeyFromTableWithName(_lookupTable, name) {
    if (typeof _lookupTable[name] !== 'undefined') {
       return Object.keys(_lookupTable).find(key => _lookupTable[key] === name);
    }
}