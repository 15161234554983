export default {
    isPasswordSave(toCheck) {
        const has8Letters = toCheck.length >= 8;
        const checkLetters = this.checkLetters(toCheck);
        const has1UpperCaseLetter = checkLetters.hasOneUpperCaseLetter;
        const has1LowerCaseLetter = checkLetters.hasOneLowerCaseLetter;
        const has1digit = toCheck.match(/[0-9]/);

        let isSave = false;
        if (has8Letters && has1UpperCaseLetter && has1LowerCaseLetter && has1digit) {
            isSave = true
        }
        return isSave;
    },
    checkLetters(toCheck) {
        let letter = {
            hasOneUpperCaseLetter: false,
            hasOneLowerCaseLetter: false
        };
        for (let i = 0; i < toCheck.length; i++) {
            const char = toCheck[i];

            if (letter.hasOneUpperCaseLetter && letter.hasOneLowerCaseLetter) {
                break;
            }

            if (!letter.hasOneUpperCaseLetter) {
                letter.hasOneUpperCaseLetter = this.hasOneUpperCaseLetter(char);
            }

            if (!letter.hasOneLowerCaseLetter) {
                letter.hasOneLowerCaseLetter = this.hasOneLowerCaseLetter(char);
            }
        }

        return letter;
    },
    hasOneUpperCaseLetter(char) {
        let hasOne = false;
        if (isNaN(char) && char == char.toUpperCase()) {
            hasOne = true;
        }
        return hasOne;
    },
    hasOneLowerCaseLetter(char) {
        let hasOne = false;
        if (isNaN(char) && char == char.toLowerCase()) {
            hasOne = true;
        }
        return hasOne;
    }
}