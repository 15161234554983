import Store from '../store';
import ApiRequest from '@/api/apiRequest';
import ErrorFormater from '@/helpers/errorFormater';

const CSV_API_PATH = "csvs";

export default {
    getCSV(companyId) {
        if (companyId === "") {
            Store.commit('updateCsv', null);
            return null;
        }

        let params = {
            'filter[company]': companyId, // 1,2,3
        }
        this.request("GET", CSV_API_PATH, params, this.handleCSVResponse);
    },
    setCSV(csvLink, activeState, companyId, callbackFunction) {
        let csv = [];
        csv["fileUrl"] = csvLink;
        csv["activeState"] = activeState;
        csv["_jv"] = {
            type: CSV_API_PATH,
            relationships: {
                company: {
                    data: {
                        type: "companies",
                        id: companyId
                    }
                }
            }
        };
        this.request("POST", csv, {}, callbackFunction);
    },
    patchCsv(csv, callbackFunction) {
        let toPatch = {
            fileUrl: csv.fileUrl,
            activeState: csv.link,
            _jv: csv._jv
        }

        if(Store.getters.hasManagerRights){
            toPatch.releaseState = csv.releaseState;
        }

        ApiRequest.deleteRelationship(toPatch);
        this.request("PATCH", toPatch, {}, callbackFunction);
    },
    deleteCsv(csvID, callbackFunction) {
        this.request("DELETE", CSV_API_PATH + "/" + csvID, {}, callbackFunction);
    },
    request(mode, pathOrObj, params, callbackFunction) {
        ApiRequest.request(
            mode,
            pathOrObj,
            (value) => callbackFunction(value),
            params
        );
    },
    handleCSVResponse(response) {
        let csv = ApiRequest.deleteJV(response);
        csv = csv[0] !== undefined ? csv[0] : "";
        Store.commit('updateCsv', csv);
        ErrorFormater.format(Store.state.csv);
    },
};