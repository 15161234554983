<template>
  <b-modal 
    id="errorMessageModal"
    title="Fehler" 
    hide-footer 
    @hide="reset()"
    >
    <div class="error-message f20 pt-0 pb-2 pl-3">
      <p>{{ $store.state.errorMessageCode500 }}</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  methods: {
    reset() {
      this.$store.state.showErrorMessageCode500 = false;
    },
  },
  computed: {
    showErrorMessageCode500: function () {
      return this.$store.state.showErrorMessageCode500;
    },
  },
  watch: {
    showErrorMessageCode500() {
      if (this.$store.state.showErrorMessageCode500) {
        this.$bvModal.show("errorMessageModal");
      }
    },
  },
};
</script>

