<template>
  <b-row id="header-area" align-v="center" align-h="between">
    <b-col id="logo" cols="4">
      <img src="../assets/Unverbrannt_Logo2.png" alt="Logo" />
      <p class="greenText d-inline-block">Marketing Tool</p>
    </b-col>

    <b-col cols="5">
      <h2
        v-if="this.$store.getters.hasCustomerRights"
        id="company-name"
        class="text-center"
      >
        {{ $store.getters.getActiveCompanyName }}
      </h2>
      <b-form-select
        v-if="$store.getters.hasManagerRights"
        v-model="selectedOrganisation"
        :options="$store.state.organisations"
        class="whiteDropdown w-75 d-block mx-auto mb-3"
        @change="selectOrganisation()"
      >
      </b-form-select>
    </b-col>

    <b-col
      v-if="$route.name !== 'Login'"
      id="userlogin"
      cols="3"
      class="d-flex justify-content-end"
    >
      <div v-if="$store.state.loginAccount !== ''">
        <p class="d-inline-block pt-2">{{ $store.state.loginAccount.name }}</p>
        <v-gravatar
          id="profilImage"
          :email="$store.state.loginAccount.email"
          default-img="mp"
          class="mx-4"
        />
      </div>
      <img
        id="logoutImage"
        v-on:click="logout()"
        src="../assets/logout.png"
        alt="logout"
        class="cursorpointer"
      />
    </b-col>
  </b-row>
</template>

<script>
import UserRequests from "@/api/userRequests";
import { organisationEventBus } from '../main';

export default {
  data() {
    return {
      selectedOrganisation: "",
    }
  },
  methods: {
    logout() {
      UserRequests.logout(null);
    },
    selectOrganisation() {
      this.$store.commit("updateSelectedOrganisation", this.selectedOrganisation);
      organisationEventBus.$emit('changeOrganisation');
    },
  },
};
</script>

<style scoped>
#header-area {
  height: 150px;
  background: url(../assets/Trennlinie-01.png) center bottom/100% 30px no-repeat;
  padding: 0 34px 20px;
  margin-bottom: -21px;
}

#logo {
  height: 60px;
  margin-right: -95px;
}

#logo p {
  font-size: 22px;
  font-weight: 400;
  padding-left: 12px;
  position: relative;
  top: 15px;
}

#company-name {
  padding-top: 29px;
  font-weight: 600;
}

#userlogin {
  padding-top: 22px;
}

#userlogin p {
  font-size: 22px;
}

#profilImage {
  width: 60px;
  height: 60px;
}

#logoutImage {
  width: 25px;
  height: 33px;
  position: relative;
  top: 12px;
}

@media screen and (max-width: 1200px) {
  #company-name {
    padding-top: 31px;
    font-size: 20px;
  }
}
</style>
