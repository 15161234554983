import Vue from "vue";
import Vuex from "vuex";
import { jsonapiModule } from 'jsonapi-vuex';
import config from '@/api/vuexJsonApiConfig';
import axios from 'axios';

Vue.use(Vuex);

const roles = {
  "admin": "admin",
  "manager": "manager",
  "customer": "customer",
  "sales": "sales"
}

const api = axios.create({
  baseURL: process.env.VUE_APP_APIURL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
});

api.interceptors.request.use(
  (config) => {
    let loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData) {
      config.headers['Authorization'] = `Bearer ${loginData.token}`
    }

    if (config.method === "patch") {
      let url = config.url;
      config.url = url.substring(5, url.length);
    }
    return config
  },
  (error) => {
    localStorage.removeItem('loginData');
    return Promise.reject(error)
  }
)

export default new Vuex.Store({
  state: {
    adminSidebarData: [
      { name: "ACCOUNT-VERWALTUNG", route: "AdminAccountManagement" },
      { name: "DATEIVERWALTUNG", route: "AdminFileManagement" },
      { name: "BANNER VERWALTEN", route: "ClientBanners" },
      { name: "CSV-DATEI", route: "ClientCsvUpload" },
    ],
    sidebarData: [
      { name: "BANNER VERWALTEN", route: "ClientBanners" },
      { name: "CSV-DATEI", route: "ClientCsvUpload" },
      { name: "DOWNLOADS", route: "ClientDownloads" },
    ],
    files: "",
    banners: null,
    csv: "",
    accounts: "",
    loginAccount: "",
    organisations: [],
    activeLanguage: "de",
    savedHistory: "",
    isPasswordWrong: false,
    downloadClicked: [],
    errorMessageCode500: "Ein Serverfehler ist aufgetreten. Die letzte Aktion wurde nicht erfolgreich durchgeführt.",
    showErrorMessageCode500: false,
    totalPages:1,
    globallySelectedOrganisation: "",
  },
  getters: {
    isAdmin: state => state.loginAccount.role === roles.admin,
    getLoginEmail: state => state.loginAccount !== "" ? state.loginAccount.email : "",
    getActiveCompanyName: state => state.loginAccount !== "" ? state.loginAccount.company.name : "",
    getActiveCompanyID: state => {
      let result = "";

      if (state.loginAccount !== undefined && state.loginAccount !== null && state.loginAccount !== "") {
        if (state.loginAccount.role !== roles.manager && state.loginAccount.role !== roles.admin) {
          result = state.loginAccount.company._jv.id;
        }
      }
      return result;
    },
    getActiveSidebar: state => {
      let result = [];

      if (state.loginAccount !== undefined && state.loginAccount !== null && state.loginAccount !== "") {
        if (state.loginAccount.role === roles.admin) {
          result = state.adminSidebarData;
        } else {
          result = state.sidebarData;
        }
      }
      return result;
    },
    getActiveLanguage: state => state.activeLanguage,
    getSavedHistorySide: state => state.savedHistory,
    hasCustomerRights: state => {
      let result = false;
      if (state.loginAccount !== undefined && state.loginAccount !== null && state.loginAccount !== "") {
        if (state.loginAccount.role === roles.customer || state.loginAccount.role === roles.manager || state.loginAccount.role == "admin") {
          result = true;
        }
      }
      return result;
    },
    hasManagerRights: state => {
      let result = false;
      if (state.loginAccount !== undefined && state.loginAccount !== null && state.loginAccount !== "") {
        if (state.loginAccount.role === roles.manager || state.loginAccount.role === roles.admin) {
          result = true;
        }
      }
      return result;
    },
    hasAdminRights: state => {
      let result = false;
      if (state.loginAccount !== undefined && state.loginAccount !== null && state.loginAccount !== "") {
        if (state.loginAccount.role === roles.admin) {
          result = true;
        }
      }
      return result;
    },
    isFileDisabled: (state) => (id) => {
      return state.downloadClicked.includes(id);
    }
  },
  mutations: {
    updateFiles(state, payload) {
      state.files = payload;
    },
    updateCsv(state, payload) {
      state.csv = payload;
    },
    updateAccounts(state, payload) {
      state.accounts = payload;
    },
    updateLoginAccount(state, payload) {
      state.loginAccount = payload;
    },
    updateOrganisations(state, payload) {
      state.organisations = payload;
    },
    updateHistory(state, payload) {
      state.savedHistory = payload;
    },
    updatePasswordState(state, payload) {
      state.isPasswordWrong = payload;
    },
    disableFile(state, payload) {
      state.downloadClicked.push(payload);
    },
    enableFile(state, payload) {
      var index = state.downloadClicked.indexOf(payload);
      if (index > -1) {
        state.downloadClicked.splice(index, 1);
      }
    },
    updateSelectedOrganisation(state, payload) {
      state.globallySelectedOrganisation = payload;
    },
  },
  modules: {
    jv: jsonapiModule(api, config),
  }
});