<template>
  <b-container>
    <div id="loginform" class="mx-auto">
      <div
        v-if="$store.state.isPasswordWrong"
        class="errorBox errorBackground px-3 py-2 mx-auto"
      >
        <p id="wrongFormatMessage" class="text-white">
          Die angegebene Kombination aus E-Mail-Adresse und Passwort ist
          ungültig.
        </p>
      </div>
      <div v-else class="errorBox px-3 py-2 mx-auto"></div>
      <div v-if="isLoginRequestMade" class="mx-auto mt-5 loader">
        <moon-loader :size="moonLoaderSize"></moon-loader>
      </div>
      <div v-else id="content" class="p-5">
        <h1 class="pb-3">Login</h1>

        <b-form-group label="Anmeldename:">
          <b-form-input
            v-model="username"
            class="inputField"
            v-on:keyup.enter="login()"
            autocomplete="on"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Kennwort:" class="pb-3">
          <b-form-input
            v-model="password"
            class="inputField"
            type="password"
            v-on:keyup.enter="login()"
          ></b-form-input>
        </b-form-group>

        <b-button class="buttonDark w-100" @click="login()">login</b-button>
        <p
          class="mt-4 greenText underline cursorpointer"
          @click="$bvModal.show('forgotPasswordModal')"
        >
          Kennwort vergessen?
        </p>
      </div>
    </div>

    <div id="footer" class="d-flex justify-content-between mx-auto">
      <p class="greenText d-inline-block">
        Noch kein Login?
        <a
          href="https://www.unverbrannt.de/"
          target="_blank"
          class="fw700 greenText"
        >
          Hier
        </a>
        zur Kontaktaufnahme.
      </p>
      <router-link class="greenText" to="imprint">Impressum</router-link>
      <router-link class="greenText" to="policy">Datenschutz</router-link>
    </div>

    <b-modal
      id="forgotPasswordModal"
      title="Kennwort vergessen"
      ref="forgotPasswordModal"
      hide-footer
    >
      <div class="px-3 pb-3">
        <p class="pb-3">Geben Sie Ihre E-Mail-Adresse ein, um fortzufahren.</p>
        <b-row class="mr-0">
          <b-col cols="8">
            <b-form-input
              v-model="resetEmail"
              class="w-100"
              placeholder="max-mustermann@example.com"
              type="email"
            >
            </b-form-input>
          </b-col>
          <b-col class="p-0">
            <b-button class="buttonDark w-100" @click="sendMail()"
              >weiter
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      id="forgotPasswordMessageModal"
      title="Kennwort vergessen"
      hide-footer
      @hide="resetForgotPasswordMessageModal"
    >
      <div class="px-3 pb-3">
        <p class="pb-3">{{ forgotPasswordMessageModalTitle }}</p>
        <p>
          {{ forgotPasswordMessageModalText }}
        </p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import UserRequests from "@/api/userRequests";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import CompanyRequests from "@/api/companyRequests";

export default {
  components: {
    MoonLoader,
  },
  data() {
    return {
      moonLoaderSize: "150px",
      isLoginRequestMade: false,
      username: "",
      password: "",
      resetEmail: "",
      forgotPasswordMessageModalTitle: "",
      forgotPasswordMessageModalText: "",
    };
  },
  methods: {
    close() {
      this.$refs.forgotPasswordModal.hide();
    },
    sendMail() {
      if (this.resetEmail !== "") {
        UserRequests.sendPasswordResetMail(
          this.resetEmail,
          this.sendMailSuccess,
          this.sendMailFailure
        );
        this.close();
        this.resetEmail = "";
      }
    },
    sendMailSuccess() {
      this.forgotPasswordMessageModalTitle =
        "Wir haben Ihnen eine E-Mail gesendet.";
      this.forgotPasswordMessageModalText =
        "Sie können Ihr Kennwort durch Klick auf den darin befindlichen Link neu setzen.";

      this.$bvModal.show("forgotPasswordMessageModal");
    },
    sendMailFailure(text) {
      this.forgotPasswordMessageModalTitle = "Es ist ein Fehler aufgetreten";
      this.forgotPasswordMessageModalText = text;
      this.$bvModal.show("forgotPasswordMessageModal");
    },
    login() {
      if (this.username !== "" && this.password !== "") {
        this.isLoginRequestMade = true;
        this.$store.commit("updatePasswordState", false);
        UserRequests.login(this.username, this.password, this.loginCallback);
      }
    },
    autoLogin() {
      let data = JSON.parse(localStorage.getItem("loginData"));
      if (data !== null) {
        this.isLoginRequestMade = true;
        this.$store.commit("updatePasswordState", false);
        UserRequests.getLoginAccount(data.id, this.loginCallback);
      }
    },
    loginCallback(response) {
      this.$store.commit("updateLoginAccount", response);
      if (response !== "") {
        this.loginSuccess();
      } else {
        this.loginFailed();
      }
    },
    loginSuccess() {
      if (this.$store.getters.hasManagerRights) {
        CompanyRequests.getCompanies();
      }
      this.forwarding();
    },
    loginFailed() {
      this.isLoginRequestMade = false;
    },
    forwarding() {
      if (
        this.$store.getters.getSavedHistorySide !== undefined &&
        this.$store.getters.getSavedHistorySide !== "" &&
        this.$router.history.current.name !==
          this.$store.getters.getSavedHistorySide
      ) {
        this.$router.push({
          name: this.$store.getters.getSavedHistorySide,
        });
      } else {
        this.$router.push({
          name: this.$store.getters.getActiveSidebar[0].route,
        });
      }
    },
    resetForgotPasswordMessageModal() {
      this.forgotPasswordMessageModalTitle = "";
      this.forgotPasswordMessageModalText = "";
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.autoLogin();
    });
  },
};
</script>

<style>
@import "../css/main.css";
@import "../css/modal.css";
.errorBox {
  width: 460px;
  height: 70px;
}
#loginform {
  width: 460px;
  margin-top: 50px;
}
#content {
  border: 1px solid var(--tipp-color);
}
.custom-control-input {
  border-radius: 0px;
}
#footer {
  width: 600px;
  margin-top: 100px;
}
</style>
