<template>
  <div>
    <div v-if="state === 'valid'">
      <img
        src="../assets/success.png"
        class="icon d-inline-block"
      />
      <p 
        v-if="!$store.getters.hasManagerRights"
        class="d-inline-block greenText fw600 pl-4"
        >Servervalidierung
      </p>
    </div>
    <div v-if="state === 'invalid' || state === 'declined'">
      <img
        src="../assets/error.png"
        class="icon d-inline-block"
      />
      <p class="d-inline-block errorText fw600 pl-4">Fehler</p>
    </div>
    <div v-if="state === 'pending'">
      <img
        src="../assets/hourglass.png"
        class="icon d-inline-block"
      />
      <p class="d-inline-block yellowText fw600 pl-4">In Prüfung bei Adcell</p>
    </div>
    <div v-if="state === 'deactivated'">
      <img
        src="../assets/deaktiviert.png"
        class="icon d-inline-block"
      />
      <p class="d-inline-block greyText fw600 pl-4">Deaktiviert</p>
    </div>
    <div v-if="state === 'activated'">
      <img
        src="../assets/aktiv.png"
        class="icon d-inline-block"
      />
      <p class="d-inline-block greenText fw600 pl-4">Released/Aktiv</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["state"],
};
</script>
