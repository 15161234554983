import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/components/Login'
import Imprint from '@/components/Imprint'
import Policy from '@/components/Policy'
import AdminFileManagement from '@/components/AdminFileManagement';
import AdminAccountManagement from '@/components/AdminAccountManagement';
import ClientBanners from '@/components/ClientBanners';
import ClientCsvUpload from '@/components/ClientCsvUpload';
import ClientDownloads from '@/components/ClientDownloads';
import LoginPasswordReset from '@/components/LoginPasswordReset';

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/resetpw/*',
            name: 'LoginPasswordReset',
            component: LoginPasswordReset
        },
        {
            path: '/adminaccount',
            name: 'AdminAccountManagement',
            component: AdminAccountManagement
        },
        {
            path: '/adminfiles',
            name: 'AdminFileManagement',
            component: AdminFileManagement
        },
        {
            path: '/imprint',
            name: 'Imprint',
            component: Imprint
        },
        {
            path: '/policy',
            name: 'Policy',
            component: Policy
        },
        {
            path: '/banners',
            name: 'ClientBanners',
            component: ClientBanners,
        },
        {
            path: '/csvs',
            name: 'ClientCsvUpload',
            component: ClientCsvUpload,
        },
        {
            path: '/downloads',
            name: 'ClientDownloads',
            component: ClientDownloads,
        },
        {
            path: '*',
            component: Login
        }
    ]
})