import Store from '../store';
import ApiRequest from '@/api/apiRequest';
import ErrorFormater from '@/helpers/errorFormater';

const BANNER_API_PATH = "banners";

export default {
    getBanners(companyId, page, isNewCompany) {
        if (companyId === "") {
            Store.state.banners = null;
            return null;
        } else if (isNewCompany) {
            Store.state.banners = null;
        }

        let params = {
            'filter[company]': companyId,
            'page[number]': page,
        }
        this.request("GET", BANNER_API_PATH, params, this.handleNewBanners);
    },
    setBanner(banner, companyID, callbackFunction) {
        banner["_jv"] = {
            type: BANNER_API_PATH,
            relationships: {
                company: {
                    data: {
                        type: "companies",
                        id: companyID
                    }
                }
            }
        };
        this.request("POST", banner, {}, callbackFunction);
    },
    patchBanner(banner, organisationId, callbackFunction) {
        let toPatch = {
            sizeType: banner.sizeType,
            imageUrl: banner.imageUrl,
            link: banner.link,
            _jv: banner._jv
        }

        if (Store.getters.hasManagerRights) {
            toPatch.releaseState = banner.releaseState;
            toPatch.activeState = banner.activeState;
        }

        if (organisationId !== null) {
            toPatch._jv.relationships.company.data.id = organisationId;
        } else {
            ApiRequest.deleteRelationship(toPatch);
        }
        this.request("PATCH", toPatch, {}, callbackFunction);
    },
    deleteBanner(bannerId, callbackFunction) {
        this.request("DELETE", BANNER_API_PATH + "/" + bannerId, {}, callbackFunction);
    },
    request(mode, pathOrObj, params, callbackFunction) {
        ApiRequest.request(
            mode,
            pathOrObj,
            (value) => callbackFunction(value),
            params
        );
    },
    handleNewBanners(response) {
        if (response._jv.json.meta != null && response._jv.json.meta != undefined) {
            Store.state.totalPages = response._jv.json.meta.totalPages;
        } else {
            Store.state.totalPages = 1;
        }

        let serverBanners = ApiRequest.deleteJV(response);
        let clientBanners = Store.state.banners;

        let needUpdate = [];
        let newBanner = [];

        if (clientBanners === null || serverBanners.length === 0) {
            Store.state.banners = [];
            clientBanners = [];
            newBanner = serverBanners;
        } else {
            for (let i = 0; i < serverBanners.length; i++) {
                let bannerIndex = Object.keys(clientBanners).findIndex(index => clientBanners[index]._jv.id === serverBanners[i]._jv.id);
                if (bannerIndex !== -1) {
                    needUpdate.push(serverBanners[i]);
                } else {
                    newBanner.push(serverBanners[i]);
                }
            }
        }

        needUpdate.forEach(banner => {
            let bannerIndex = Object.keys(Store.state.banners).findIndex(index => Store.state.banners[index]._jv.id === banner._jv.id);
            Store.state.banners[bannerIndex] = banner;
        });

        newBanner.forEach(banner => {
            Store.state.banners.push(banner);
        });

        for (let i = 0; i < clientBanners.length; i++) {
            let bannerIndex = Object.keys(serverBanners).findIndex(index => serverBanners[index]._jv.id === clientBanners[i]._jv.id);
            if (bannerIndex === -1) {
                delete Store.state.banners[i];
            }
        }

        Store.state.banners = Store.state.banners.filter(e => e !== null);
        Store.state.banners.forEach(element => {
            ErrorFormater.format(element);
        });
    },
};