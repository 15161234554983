import Store from '../store';
import ApiRequest from '@/api/apiRequest';
import axios from 'axios';
import Sorting from "@/helpers/sorting";

const JSON_FILE_API_PATH = "files";
const UPLOAD_FILE_API_PATH = "files/upload";
const DOWNLOAD_FILE_API_PATH = "files/download";

export default {
    setFile(file, callbackFunction) {
        file["_jv"] = {
            type: JSON_FILE_API_PATH,
            relationships: {
                company: {
                    data: {
                        type: "companies",
                        id: file.company === "" ? "0" : file.company
                    }
                }
            }
        };
        file["size"] = 0;
        delete file.file;
        delete file.company;
        this.request("POST", file, {}, callbackFunction);
    },
    patchFile(file, callbackFunction) {

        let toPatch = {
            name: file.name,
            _jv: file._jv
        }

        let newId = file.company === "" ? "0" : file.company;
        if (toPatch._jv.relationships.company.data === null) {
            toPatch._jv.relationships.company.data = {
                type: "companies",
                id: newId
            }
        } else {
            toPatch._jv.relationships.company.data.id = newId;
        }
        this.request("PATCH", toPatch, {}, callbackFunction);
    },
    deleteFile(fileID, callbackFunction) {
        this.request("DELETE", JSON_FILE_API_PATH + "/" + fileID, {}, callbackFunction);
    },
    getFiles(companyIds) {
        if (companyIds === "") {
            Store.commit('updateFiles', []);
            return null;
        }

        let params = {
            'filter[company]': companyIds,
            'filter[sortCriterion]': 'id',
            'filter[sortOrder]': 'desc',
        }
        this.request("GET", JSON_FILE_API_PATH, params, this.handleNewFiles);
    },
    request(mode, pathOrObj, params, callbackFunction) {
        ApiRequest.request(
            mode,
            pathOrObj,
            (value) => callbackFunction(value),
            params
        );
    },
    handleNewFiles(response) {
        var files = ApiRequest.deleteJV(response);
        var result = Sorting.sort(
            "uploadTime",
            files,
            true
        );
        Store.commit('updateFiles', result);
    },
    downloadRequest(fileId, fileName, callbackFunction) {
        let loginData = JSON.parse(localStorage.getItem('loginData'));
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${loginData.token}`
            },
        };

        fetch(`${process.env.VUE_APP_APIURL}/${DOWNLOAD_FILE_API_PATH}/${fileId}`, requestOptions)
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = fileName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                callbackFunction(fileId);
            });
    },
    uploadRequest(file, fileId, callbackUploadSuccess, callbackUploadFailed) {
        let loginData = JSON.parse(localStorage.getItem('loginData'));

        const formData = new FormData();
        formData.append('file', file);

        const api = axios.create({
            baseURL: `${process.env.VUE_APP_APIURL}`,
            withCredentials: false,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${loginData.token}`
            }
        });
        api.post(`/${UPLOAD_FILE_API_PATH}/${fileId}`, formData,
            {})
            .then(function (response) {
                callbackUploadSuccess(response);
            })
            .catch(function (error) {
                callbackUploadFailed(error);
            });
    },
};