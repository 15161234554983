const accessMode = {
    "POST": "post",
    "PATCH": "patch",
    "DELETE": "delete",
    "GET": "get"
};

export default function getAccessMode(mode) {
    if (typeof accessMode[mode] !== 'undefined') {
        return accessMode[mode];
    }

    return "error";
}