<template>
  <div class="d-flex flex-nowrap">
    <div id="sidebar border-right">
      <Sidebar />
    </div>

    <div class="container mx-5">
      <h2 class="mt-5 mb-3">Dateien verwalten</h2>

      <div
        v-if="this.$store.state.globallySelectedOrganisation === ''"
        class="seablueContainer px-3 py-4 mt-3"
      >
        <p class="text-white">
          Es muss eine Organisation/ Firma ausgewählt sein, um deren Dateien
          sehen zu können.
        </p>
      </div>
      <div v-if="this.$store.state.globallySelectedOrganisation != ''">
        <div class="containerBackground px-4 py-3">
          <img
            @click="saveFileData('', 'addNewFileModal')"
            src="../assets/add.png"
            class="icon cursorpointer"
          />
          <p class="d-inline-block ml-3">Neue Datei hochladen</p>
        </div>
        <div v-if="$store.state.files === ''" class="mx-auto mt-5 loader">
          <moon-loader :size="bigMoonLoaderSize"></moon-loader>
        </div>
        <div v-else>
          <h2 class="d-inline-block mt-5">
            Dateien für {{ this.companyName }}
          </h2>

          <div v-if="companyFiles.length === 0">
            <p class="fw300 pl-2">Keine Dateien vorhanden</p>
          </div>

          <div
            v-if="uploadFailedUIOutput"
            class="errorBackground px-3 py-2 mb-3"
          >
            <p class="text-white">Upload fehlgeschlagen</p>
          </div>

          <table v-if="companyFiles.length != 0" class="w-100">
            <tr>
              <th>Dateiname</th>
              <th class="text-right pr-3">Dateityp</th>
              <th class="text-right pr-3">Hochgeladen</th>
              <th class="text-right pr-4">Größe</th>
              <th>Download</th>
              <th class="text-center">Edit.</th>
              <th class="text-center">Löschen</th>
            </tr>
            <tr v-for="(file, index) in companyFiles" :key="index">
              <td class="big-width">{{ file.name }}</td>
              <td class="small-width text-right pr-3 no-br">
                {{ file.extension }}
              </td>
              <td class="middle-width text-right pr-3 no-br no-bl">
                {{ displayUploadDate(file.uploadTime) }}
              </td>
              <td class="small-width text-right pr-4 no-bl">
                {{ displayFileSize(file.size) }}
              </td>
              <td class="text-center no-br no-bl small-width">
                <img
                  src="../assets/download.png"
                  alt="download button"
                  @click="download(file.id, file.name + '.' + file.extension)"
                  class="icon cursorpointer no-br"
                />
              </td>
              <td class="text-center no-br no-bl small-width">
                <img
                  src="../assets/edit.png"
                  alt="edit button"
                  @click="saveFileData(file, 'editFileModal')"
                  class="icon cursorpointer no-br"
                />
              </td>
              <td class="text-center no-bl small-width">
                <img
                  src="../assets/delete.png"
                  alt="delete button"
                  @click="saveFileData(file, 'deleteFileModal')"
                  class="icon cursorpointer"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="$store.state.files === ''" class="mx-auto mt-5 loader">
        <moon-loader :size="bigMoonLoaderSize"></moon-loader>
      </div>

      <div v-else class="mb-5">
        <h2 class="d-inline-block mt-5">Dateien für alle Organisationen</h2>
        <div v-if="overallFiles.length === 0">
          <p class="fw300 pl-2">Keine Dateien vorhanden</p>
        </div>
        <div v-if="uploadFailedUIOutput" class="errorBackground px-3 py-2 mb-3">
          <p class="text-white">Upload fehlgeschlagen</p>
        </div>

        <table v-if="overallFiles.length != 0" class="w-100">
          <tr>
            <th>Dateiname</th>
            <th class="text-right pr-3">Dateityp</th>
            <th class="text-right pr-3">Hochgeladen</th>
            <th class="text-right pr-4">Größe</th>
            <th>Download</th>
            <th class="text-center">Edit.</th>
            <th class="text-center">Löschen</th>
          </tr>
          <tr v-for="(file, index) in overallFiles" :key="index">
            <td class="big-width">{{ file.name }}</td>
            <td class="small-width text-right pr-3 no-br">
              {{ file.extension }}
            </td>
            <td class="middle-width text-right pr-3 no-br no-bl">
              {{ displayUploadDate(file.uploadTime) }}
            </td>
            <td class="small-width text-right pr-4 no-bl">
              {{ displayFileSize(file.size) }}
            </td>
            <td class="text-center no-br no-bl small-width">
              <img
                src="../assets/download.png"
                alt="download button"
                @click="download(file.id, file.name + '.' + file.extension)"
                class="icon cursorpointer no-br"
              />
            </td>
            <td class="text-center no-br no-bl small-width">
              <img
                src="../assets/edit.png"
                alt="edit button"
                @click="saveFileData(file, 'editFileModal')"
                class="icon cursorpointer no-br"
              />
            </td>
            <td class="text-center no-bl small-width">
              <img
                src="../assets/delete.png"
                alt="delete button"
                @click="saveFileData(file, 'deleteFileModal')"
                class="icon cursorpointer"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <b-modal
      id="addNewFileModal"
      title="Neue Datei hochladen"
      hide-footer
      @hide="reset()"
    >
      <div class="px-3">
        <p class="f22">Datei auswählen</p>
        <b-form-file
          v-model="activeFile.file"
          class="mt-2 mb-3"
          plain
          @input="checkFile()"
        ></b-form-file>
        <p v-if="fileSizeWarning" class="error-message f20 pt-0 pb-3">
          Dateigröße darf höchstens 5MB sein.
        </p>
        <p v-if="fileTypeWarning" class="error-message f20 pt-0 pb-3">
          Dateityp nicht erlaubt
        </p>
        <p class="f22">Neuen Dateinamen eingeben</p>
        <b-form-input
          v-model="activeFile.name"
          placeholder=""
          onfocus="this.placeholder = ''"
          class="f18 fw300 mb-3 w-100 fileName-input"
        >
        </b-form-input>

        <p class="f22">Organisation/Firma auswählen</p>
        <b-form-select
          v-model="activeFile.company"
          text="Organisation/Firma auswählen"
          class="f18 mb-3 w-100 company-dropdown"
          :options="organisations"
        >
        </b-form-select>

        <b-button
          class="buttonDark f22 w-100 py-2 mt-3 mb-4"
          @click="createFile()"
        >
          hochladen
        </b-button>
        <p v-if="showError" class="error-message f20">
          Bitte Datei auswählen und Dateinamen eingeben.
        </p>
        <div v-if="fileUploading" class="mx-auto loader">
          <moon-loader></moon-loader>
        </div>
      </div>
    </b-modal>

    <b-modal id="editFileModal" title="Editieren" hide-footer @hide="reset()">
      <div class="px-3">
        <p class="f22 fw400 mt-n-5 mb-3">{{ activeFile.name }}</p>
        <p class="f22">Neue Datei hochladen</p>
        <b-form-file
          v-model="activeFile.file"
          class="mt-2 mb-3 pt-0"
          plain
          @input="checkFile()"
        ></b-form-file>
        <p v-if="fileSizeWarning" class="error-message f20 pt-0 pb-3">
          Dateigröße darf höchstens 5MB sein.
        </p>
        <p v-if="fileTypeWarning" class="error-message f20 pt-0 pb-3">
          Dateityp nicht erlaubt
        </p>

        <p class="f22">Neuen Dateinamen eingeben</p>
        <b-form-input
          v-model="activeFile.name"
          placeholder=""
          onfocus="this.placeholder = ''"
          class="f18 fw300 mb-3 w-100 fileName-input"
        >
        </b-form-input>

        <p class="f22">Organisation/Firma auswählen</p>
        <b-form-select
          v-model="activeFile.company"
          text="Organisation/Firma auswählen"
          class="f18 mb-3 w-100 company-dropdown"
          :options="organisations"
        >
        </b-form-select>

        <b-button
          class="buttonDark f22 w-100 py-2 mt-3 mb-4"
          @click="updateFile()"
        >
          Änderungen speichern
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="deleteFileModal"
      hide-footer
      @hide="reset()"
      title="Datei wirklich löschen?"
    >
      <p class="greenText f24 fw400 pl-4">{{ activeFile.name }}</p>

      <b-row class="text-center w-100 pt-4 ml-2 pr-3">
        <b-col cols="6">
          <b-button class="buttonDark w-100" @click="deleteFile()">ja</b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            class="buttonDark w-100"
            @click="$bvModal.hide('deleteFileModal')"
          >
            nein
          </b-button>
        </b-col>
      </b-row>
      <p
        v-if="this.activeFile.company === ''"
        class="redText f20 fw600 pl-4 pt-3"
      >
        ACHTUNG: Die Datei wird für alle Organisationen gelöscht.
      </p>
    </b-modal>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import FileRequests from "@/api/fileRequests";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import { organisationEventBus } from "../main";

export default {
  components: {
    Sidebar,
    MoonLoader,
  },
  data() {
    return {
      bigMoonLoaderSize: "150px",
      companyFiltering: "",
      activeFile: "",
      fileInProcessing: false,
      fileUploading: false,
      showError: false,
      fileSizeWarning: false,
      fileTypeWarning: false,
      uploadFailedUIOutput: false,
      companyName: "",
    };
  },
  methods: {
    createFile() {
      if (
        this.checkInputs(this.activeFile) &&
        !this.fileSizeWarning &&
        !this.fileTypeWarning
      ) {
        this.fileUploading = true;
        let file = JSON.parse(JSON.stringify(this.activeFile));
        FileRequests.setFile(file, this.uploadFileAfterCreate);
      } else {
        this.showError = true;
      }
    },
    uploadFileAfterCreate(file) {
      if (file !== null) {
        FileRequests.uploadRequest(
          this.activeFile.file,
          file.id,
          this.uploadSuccess,
          this.uploadFailed
        );
        this.reloadFiles();
      }
      this.fileUploading = false;
      this.$bvModal.hide("addNewFileModal");
    },
    uploadSuccess() {
      this.reloadFiles();
    },
    uploadFailed() {
      this.uploadFailedUIOutput = true;
    },
    updateFile() {
      if (!this.fileSizeWarning && !this.fileTypeWarning) {
        this.fileUploading = true;
        let file = JSON.parse(JSON.stringify(this.activeFile));
        FileRequests.patchFile(file, this.uploadFileAfterUpdate);
      }
    },
    uploadFileAfterUpdate() {
      if (this.activeFile.file !== undefined) {
        FileRequests.uploadRequest(
          this.activeFile.file,
          this.activeFile.id,
          this.uploadSuccess,
          this.uploadFailed
        );
      }
      this.reloadFiles();
      this.fileUploading = false;
      this.$bvModal.hide("editFileModal");
    },
    deleteFile() {
      FileRequests.deleteFile(this.activeFile.id, this.reloadFiles);
      this.$bvModal.hide("deleteFileModal");
    },
    reset() {
      this.fileInProcessing = false;
      this.activeFile = {
        file: null,
        name: "",
        company: this.$store.state.globallySelectedOrganisation,
      };
      this.showError = false;
    },
    saveFileData(file, modalName) {
      if (file !== "") {
        this.activeFile = JSON.parse(JSON.stringify(file));
        if (this.activeFile.company._jv !== undefined) {
          this.activeFile["company"] = file.company._jv.id;
        } else {
          this.activeFile["company"] = "";
        }
      } else {
        this.activeFile["company"] =
          this.$store.state.globallySelectedOrganisation;
      }
      this.fileInProcessing = true;
      this.$bvModal.show(modalName);
    },
    reloadFiles() {
      this.uploadFailedUIOutput = false;
      this.resetFiles();
      this.getFiles();
    },
    getFiles() {
      let ids = "0";
      if (this.$store.state.globallySelectedOrganisation !== "") {
        ids += "," + this.$store.state.globallySelectedOrganisation;
      }
      FileRequests.getFiles(ids);
    },
    download(fileId, fileName) {
      FileRequests.downloadRequest(fileId, fileName);
    },
    resetFiles() {
      this.$store.commit("updateFiles", "");
    },
    checkInputs(file) {
      let result = true;
      if (file.name === "" || file.file === null) {
        result = false;
      }
      return result;
    },
    checkFile() {
      if (this.activeFile.file !== null) {
        this.fileSizeWarning = false;
        this.fileTypeWarning = false;

        if (this.activeFile.file.size > 5000000) {
          this.fileSizeWarning = true;
        }

        const acceptedFileTypes = [
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "application/pdf",
          "application/xml",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/rtf",
          "text/csv",
          "text/rtf",
          "text/xml",
          "text/plain",
          "image/png",
          "image/jpeg",
        ];
        const fileAccepted = (filetype, acceptedTypes) =>
          acceptedTypes.some((type) => type === filetype.type);
        if (fileAccepted(this.activeFile.file, acceptedFileTypes) === false) {
          this.fileTypeWarning = true;
        }
      }
    },
    displayFileSize(fileSize) {
      if (fileSize < 1000000) {
        return (this.fileSize = Math.round(fileSize / 1000) + " kB");
      } else {
        return (this.fileSize = (fileSize / 1000000).toFixed(1) + " MB");
      }
    },
    displayUploadDate(unixTimeStamp) {
      const dateObject = new Date(unixTimeStamp * 1000);
      return dateObject.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    setCompanyName() {
      const organisation = this.$store.state.organisations.find(
        (element) =>
          element.value === this.$store.state.globallySelectedOrganisation
      );
      this.companyName = organisation.text;
    },
  },
  computed: {
    loginAccount: function () {
      return this.$store.state.loginAccount;
    },
    organisations: function () {
      let organisations = JSON.parse(
        JSON.stringify(this.$store.state.organisations)
      );
      if (organisations.length > 0) {
        organisations[0].text = "Alle Organisationen";
      }

      return organisations;
    },
    companyFiles: function () {
      return this.$store.state.files.filter(function (el) {
        return el.company.name;
      });
    },
    overallFiles: function () {
      return this.$store.state.files.filter(function (el) {
        return el.company.name === undefined;
      });
    },
  },
  watch: {
    loginAccount() {
      this.getFiles();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.reset();
      this.getFiles();
      this.setCompanyName();
      this.activeFile.company = this.$store.state.globallySelectedOrganisation;
    });
    organisationEventBus.$on("changeOrganisation", () => {
      this.getFiles();
      this.setCompanyName();
      this.activeFile.company = this.$store.state.globallySelectedOrganisation;
    });
  },
};
</script>

<style scoped>
@import "../css/main.css";
@import "../css/modal.css";
@import "../css/dropdowns.css";

td {
  border: 1px solid #bbbbbb;
  border-collapse: collapse;
}
th,
td {
  font-weight: 300;
  font-size: 18px;
  padding: 10px;
}
.no-br {
  border-right: none;
}
.no-bl {
  border-left: none;
}
.big-width {
  width: 28%;
}
.middle-width {
  width: 15%;
}
.small-width {
  width: 9%;
}
#addNewFileModal___BV_modal_header_.modal-header {
  padding-bottom: 0 !important;
}
.company-dropdown .dropdown-toggle {
  border: none;
}
.company-dropdown,
.fileName-input,
.link-input {
  border: 1px solid var(--tipp-color);
  text-align: left;
}
</style>
