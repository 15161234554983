export default {
    sort(condition, toSort, isDESC) {
        let sortValue1 = 1;
        let sortValue2 = -1;
        if (isDESC) {
            sortValue1 = -1;
            sortValue2 = 1;
        }
        let sortedArr = toSort.sort((a, b) =>
            this.getObjectValue(a, condition) > this.getObjectValue(b, condition)
                ? sortValue1
                : sortValue2
        );
        return sortedArr;
    },
    getObjectValue(obj, condition) {
        if (condition === "company") {
            return obj[condition].name !== undefined ? obj[condition].name.toLowerCase() : "";
        }if (condition === "uploadTime") {
            return obj[condition];
        } else {
            return obj[condition].toLowerCase();
        }
    }
}