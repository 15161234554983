<template>
  <div class="d-flex flex-nowrap">
    <div id="sidebar border-right">
      <Sidebar />
    </div>

    <div id="policy-container" class="container mx-5 mb-5">
      <h2 class="my-5">Datenschutzerklärung</h2>
      <p>
        Im Folgenden möchten wir Sie über die durch uns durchgeführte
        Verarbeitung personenbezogener Daten bei Besuch unsere Webseite nach
        Maßgabe der EU-Datenschutz-Grundverordnung (im Folgenden: DSGVO)
        hinweisen.
      </p>
      <p>
        Nachstehende Regelungen informieren Sie über die Art, den Umfang und den
        Zweck der Erhebung, die Nutzung und die Verarbeitung von
        personenbezogenen Daten durch die:
      </p>
      <p>
        LoCoNET GmbH<br />
        Marlene-Dietrich-Allee 15<br />
        14482 Potsdam<br />
        Telefon: 0331/73098829<br />
        E-Mail: kontakt@loconet.info
      </p>

      <p>
        Bitte lesen Sie sich unsere Datenschutzerklärung sorgfältig durch.
        Sollten Sie Fragen oder Anmerkungen zu dieser Datenschutzerklärung
        haben, können Sie diese jederzeit an die unter Ziffer 1 angegebene E-
        Mail-Adresse richten.
      </p>

      <h3>
        1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
      </h3>
      <p>
        Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer
        personenbezogenen Daten ist die:
      </p>
      <p>
        LoCoNET GmbH<br />
        Marlene-Dietrich-Allee 15<br />
        14482 Potsdam<br />
        (im Folgenden: LoCoNET)
      </p>
      <p>
        Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch die
        LoCoNET nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für
        einzelne Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch
        per E-Mail, Fax oder Brief an die LoCoNET senden:
      </p>
      <p>
        LoCoNET GmbH<br />
        Marlene-Dietrich-Allee 15<br />
        14482 Potsdam<br />
        Telefon: 0331/73098829<br />
        Fax: 0331/73098821<br />
        E-Mail: datenschutz@loconet.info
      </p>
      <p>
        Auf schriftliche Anfrage werden wir Sie auch jederzeit unentgeltlich
        gern über die von uns zu Ihrer Person gespeicherten Daten informieren.
      </p>

      <h3>2. Cookies</h3>
      <p>
        Auf unserer Seite werden Cookies zum Wiedererkennen mehrfacher Nutzung
        unserer Webpräsenz durch denselben Nutzer bzw. Internetanschlussinhaber
        eingesetzt. Dabei handelt es sich um kleine Textdateien, die der
        Internet-Browser auf Ihrem Rechner ablegt und speichert. Sie dienen der
        Optimierung unseres Internetauftritts. Überwiegend handelt es sich um
        Session-Cookies, die nach dem Ende Ihres Besuches wieder gelöscht
        werden.
      </p>
      <p>
        Darüber hinaus werden Cookies verwendet, die anhand von Informationen es
        ermöglichen, Sie automatisch wieder zu erkennen. Gespeichert wird in den
        Cookies für diese Wiedererkennung die IP-Adresse. Dies ermöglicht uns,
        unsere Angebote zu optimieren und dem Nutzer einen leichteren Zugang auf
        unsere Seite zu gewähren.
      </p>
      <p>
        Die Installation der Cookies kann durch eine entsprechende Einstellung
        des Browsers verhindern werden. In diesem Fall können möglicherweise
        nicht alle Funktionen unserer Webseite vollumfänglich genutzt werden.
      </p>

      <h3>
        3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck
        von deren Verwendung
      </h3>
      <p>
        Beim Aufrufen unserer Webseite https://mein.unverbrannt.de werden aus
        technischen Gründen durch den Internet-Browser an uns bzw. an unseren
        Webspace-Provider Informationen übermittelt bzw. erfasst. Diese
        Informationen werden temporär in einem sogenannten Logfile gespeichert.
        Folgende Informationen werden dabei ohne Ihr Zutun erfasst und
        spätestens nach 9 Wochen gelöscht:
      </p>
      <ul>
        <li>
          verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
          der Name Ihres Access- Providers
        </li>
        <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL)</li>
        <li>Name und URL der abgerufenen Datei</li>
        <li>Datum und Uhrzeit des Zugriffs</li>
        <li>IP-Adresse des anfragenden Rechners</li>
      </ul>
      <p>
        Die Informationen dienen der Sicherstellung eines problemlosen
        Verbindungsaufbaus sowie einer reibungslosen Nutzung der Webseite, der
        Auswertung der Systemsicherheit und -stabilität sowie weiteren
        administrativen Zwecken. Diese Daten werden getrennt von möglicherweise
        angegebenen personenbezogenen Daten, E-Mailadresse, Name, Alter ect.
        gespeichert. Rückschlüsse auf eine bestimmte Person sind nicht möglich.
        Es erfolgt eine Auswertung zu statistischen Zwecken, um unseren
        Internetauftritt weiter optimieren zu können.
      </p>

      <h3>4. Registrierung und Dauer der Datenverarbeitung</h3>
      <p>
        Sofern Sie sich auf unserer Plattform registriert haben, werden zu den
        oben genannten Daten auch weitere, eindeutig auf Sie beziehbare Daten
        verarbeitet. Die nachfolgend beschriebene Verarbeitung personenbezogener
        Daten, d.h. Informationen die Ihnen als Person unmittelbar zugeordnet
        werden können, sind gesetzlich nicht notwendig, jedoch erforderlich,
        damit Sie die Plattform verwenden können.
      </p>
      <p>
        Wenn Sie sich auf unserer Online-Plattform registrieren, verarbeiten wir
        die dabei von Ihnen angegebenen Daten, um ein Nutzerkonto für Sie
        anzulegen, Sie als Vertragspartner in unserem System zu verwalten und
        betreuen zu können, Sie bei Ihrem Login wiederzuerkennen und Ihnen die
        von Ihnen eingegebenen Daten erneut anzeigen zu können. Das Gleiche
        gilt, wenn Sie Funktionen auf unserer Online-Plattform verwenden, für
        die Sie sich vorher einloggen müssen.
      </p>
      <p>
        Rechtsgrundlage ist der Vertrag zur Nutzung der Online-Plattform, Art. 6
        Abs. 1 lit. b DSGVO, da die Verarbeitung dieser Daten zur Erfüllung des
        Vertrages über die Nutzung des Portals erforderlich ist. Dies gilt auch
        für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
        Maßnahmen erforderlich sind.
      </p>
      <p>
        Wenn Daten für die Vertragserfüllung erforderlich sind (sog.
        Pflichtangaben), informieren wir Sie darüber. Teilen Sie uns
        Informationen mit, die keine Pflichtangaben für die Registrierung sind,
        basiert die Verarbeitung dieser Daten auf Ihrer Einwilligung, Art. 6
        Abs. 1 lit. a DSGVO. Sie haben insoweit nach Art. 7 Abs. 3 DSGVO
        jederzeit das Recht, mit Wirkung für die Zukunft diese Einwilligung zu
        widerrufen. Hierfür melden Sie sich einfach in Ihrem Nutzerkonto online
        an und löschen die Daten. Alternativ senden Sie uns eine E-Mail an
        datenschutz@loconet.info, dass wir die Informationen zukünftig nicht
        mehr verwenden sollen.
      </p>
      <p>
        Außerdem verarbeiten wir zur Erfüllung unserer rechtlichen
        Verpflichtungen insbesondere aus Steuer- und Handelsrecht sowie
        strafrechtlichen Nebengesetzen und den Vorschriften zum Schutz der
        öffentlichen Sicherheit und Ordnung die im Zusammenhang mit Ihrer
        Registrierung erhobenen personenbezogenen Daten. Rechtsgrundlage hierfür
        ist Art. 6 Abs. 1 lit. c DSGVO.
      </p>
      <p>
        Personenbezogene Daten im Zusammenhang mit Ihrer Registrierung werden
        gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht
        mehr erforderlich sind. Dies ist für die während des
        Registrierungsvorgangs erhobenen Daten der Fall, wenn die Registrierung
        auf unserer Internetseite aufgehoben oder abgeändert wird. Dies ist
        ebenfalls für die während des Registrierungsvorgangs zur Erfüllung eines
        Vertrags oder zur Durchführung vorvertraglicher Maßnahmen der Fall, wenn
        die Daten für die Durchführung des Vertrages nicht mehr erforderlich
        sind.
      </p>
      <p>
        Auch nach Abschluss des Vertrags kann es erforderlich sein,
        personenbezogene Daten von Ihnen als Vertragspartner zu speichern, um
        vertraglichen oder gesetzlichen Verpflichtungen nachzukommen.
      </p>

      <h3>5. Kontaktmöglichkeit</h3>
      <p>
        Wir bieten Ihnen auf unserer Webseite die Möglichkeit, sich mit uns per
        E-Mail in Verbindung zu setzen. Soweit Sie uns personenbezogene Daten
        zur Verfügung stellen, verwenden wir diese nur zur Bearbeitung bzw.
        Beantwortung Ihrer Anfragen, zur Kontaktaufnahme und für die technische
        Administration. Eine Weitergabe an Dritte erfolgt nicht. Ein Abgleich
        dieser Daten mit Daten, die möglicherweise durch andere Komponenten
        unserer Webseite erhoben werden, erfolgt nicht.
      </p>

      <h3>6. Datensicherheit</h3>
      <p>
        Wir sind bemüht, personenbezogenen Daten durch Einsatz aller technischen
        und organisatorischen Möglichkeiten so zu speichern, dass sie für Dritte
        nicht zugänglich sind. Um die Sicherheit Ihrer Daten bei der Übertragung
        zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren über HTTPS. Bei der Kommunikation per E-Mail
        kann die vollständige Datensicherheit von uns nicht gewährleistet
        werden, daher empfehlen wir bei vertraulichen Informationen den Postweg.
      </p>

      <h3>7. Betroffenenrechte</h3>
      <p>Sie haben das Recht:</p>
      <ul>
        <li>
          gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit
          gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
          Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
          Zukunft nicht mehr fortführen dürfen.
        </li>
        <li>
          gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
          über die Verarbeitungszwecke, die Kategorie der personenbezogenen
          Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
          offengelegt wurden oder werden, die geplante Speicherdauer, das
          Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
          Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
          die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden,
          sowie über das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich Profiling und ggf. aussagekräftigen Informationen zu
          deren Einzelheiten verlangen.
        </li>
        <li>
          gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
          Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten
          zu verlangen.
        </li>
        <li>
          gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
          personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
          zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist.
        </li>
        <li>
          gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
          von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen; wir die Daten nicht mehr benötigen, Sie
          jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch
          gegen die Verarbeitung eingelegt haben.
        </li>
        <li>
          gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen.
        </li>
        <li>
          gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In
          der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres
          üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
          Unternehmenssitzes wenden.
        </li>
      </ul>

      <h3>8. Aktualität und Änderung unserer Datenschutzbestimmungen</h3>
      <p>
        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
        September 2020. Wir behalten uns vor, diese Datenschutzerklärung
        anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen
        entspricht. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf
        der Webseite unter der https://mein.unverbrannt.de/#/policy abgerufen
        und ausgedruckt werden. Für einen Besuch unserer Webseite gilt die
        jeweils gültige Datenschutzerklärung.
      </p>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>


<style scoped>
@import "../css/main.css";

#policy-container {
  max-width: 1100px;
}
h3 {
  font-size: 17px;
  color: var(--green-color);
  font-weight: 600;
  margin: 2.5rem 0 1rem;
}
p {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 1rem;
}
li {
  font-size: 16px;
  font-weight: 300;
}
</style>
