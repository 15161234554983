<template>
  <div class="d-flex flex-nowrap">
    <div id="sidebar border-right">
      <Sidebar />
    </div>

    <div class="container mx-5">
      <h2 class="my-5">Impressum</h2>

      <div id="imprint-container">
        <p class="pb-3">
          UNVERBRANNT ist eine eingetragene<br />Marke der
          <a href="https://www.loconet.info" target="_blank" class="greenText">LoCoNET GmbH</a>
        </p>
        <div class="fw300">
          <p>LoCoNET GmbH</p>
          <p>Marlene-Dietrich-Allee 15</p>
          <p class="pb-3">14482 Potsdam</p>
          <p>Tel: 0331 730 988 29</p>
          <p class="pb-3">kontakt@unverbrannt.de</p>
          <p class="pb-3">Vertretungsberechtigt: André Walter, Artur Lippok</p>
          <p>Registergericht: Amtsgericht Potsdam</p>
          <p>Registernummer: HRB 23226 P</p>
          <p class="pb-3">USt-IdNr.: DE272417341</p>
        </div>
        <router-link :to="{ name: 'Policy' }">
          <p class="greenText underline">Datenschutz</p>
        </router-link>
        <p>
          <a
            href="https://www.unverbrannt.de/?page_id=308"
            target="_blank"
            class="greenText"
            >AGB</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
@import "../css/main.css";

#imprint-container {
  width: 400px;
}
</style>
