import Store from '../store';
import ApiRequest from '@/api/apiRequest';

const COMPANY_API_PATH = "companies";

export default {
    getCompanies() {
        this.request("GET", COMPANY_API_PATH, {}, this.handleNewCompanies);
    },
    setCompany(company, callbackFunction) {
        company["_jv"] = {
            type: COMPANY_API_PATH,
        };
        this.request("POST", company, {}, callbackFunction);
    },
    patchCompany(company) {
        let toPatch = {
            name: company.name,
            email: company.email,
            website: company.website,
            phone: company.phone,
            programID: company.programID,
            dataStudioPraefix: company.dataStudioPraefix,
            _jv: {
                type: company._jv.type,
                id: company._jv.id,
                links: company._jv.links
            }
        }
        this.request("PATCH", toPatch, {}, () => this.getCompanies());
    },
    request(mode, pathOrObj, params, callbackFunction) {
        ApiRequest.request(
            mode,
            pathOrObj,
            (value) => callbackFunction(value),
            params
        );
    },
    handleNewCompanies(response) {
        let organisations = [];
        organisations.push({ value: "", text: "Organisation/Firma wählen", data: { email: "", website: "", phone: "", programID: null, dataStudioPraefix: "", _jv: "" } });
        Object.keys(response).forEach((key) => {
            if (key !== '_jv') {
                organisations.push({ 
                    value: response[key]._jv.id, 
                    text: response[key].name, 
                    data: { email: response[key].email, 
                        website: response[key].website, 
                        phone: response[key].phone, 
                        programID: response[key].programID, 
                        dataStudioPraefix: response[key].dataStudioPraefix,
                        _jv: response[key]._jv } });
            }
        });
        Store.commit('updateOrganisations', organisations);
    },
};