import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import FileSelector from 'vue-file-selector';
import Gravatar from 'vue-gravatar';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from './App.vue';
import router from './router';
import store from "./store";

Vue.use(BootstrapVue);
Vue.use(FileSelector);
Vue.component('v-gravatar', Gravatar);
Vue.config.productionTip = false;

export const organisationEventBus = new Vue();

window.confApp = new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app');