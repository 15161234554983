import Store from '../store';
import ApiRequest from '@/api/apiRequest';

const ACCOUNT_API_PATH = "users";
const ACCOUNT_LOGIN_API_PATH = "login";

export default {
    sendPasswordResetMail(email, callbackSuccess, callbackError) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: 'email=' + encodeURI(email)
        };
        fetch(`${process.env.VUE_APP_APIURL}/${ACCOUNT_API_PATH}/forgottenpw`, requestOptions)
            .then(response => {
                console.log(response);
                if (response.status == 200) {
                    callbackSuccess();
                } else if (response.status == 429) {
                    callbackError("Zu viele Versuche.");
                } else {
                    callbackError("");
                }
            });
    },
    setNewPassword(urlToken, newPw, callbackSuccess, callbackError) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: 'key=' + encodeURI(urlToken) + '&newPW=' + encodeURIComponent(newPw)
        };
        fetch(`${process.env.VUE_APP_APIURL}/setpw`, requestOptions)
            .then(response => {
                if (response.status == 200) {
                    callbackSuccess();
                } else if (response.status == 429) {
                    callbackError("Zu viele Versuche.");
                } else {
                    callbackError("");
                }
            });
    },
    patchAccount(account, callbackFunction) {
        let toPatch = {
            name: account.name,
            email: account.email,
            role: account.role,
            _jv: account._jv
        }

        if (account.role !== "admin" && account.role !== "manager") {

            toPatch._jv.relationships.company.data.id = account.organisation;
        }

        this.request("PATCH", toPatch, {}, callbackFunction);
    },
    deleteAccount(accountId, callbackFunction) {
        this.request("DELETE", ACCOUNT_API_PATH + "/" + accountId, {}, callbackFunction);
    },
    addAccount(account, callbackFunction) {
        account["_jv"] = {
            type: ACCOUNT_API_PATH,
            relationships: {
                company: {
                    data: {
                        type: "companies",
                        id: account.organisation
                    }
                }
            }
        };
        account["password"] = " ";
        this.request("POST", account, {}, callbackFunction);
    },
    request(mode, pathOrObj, params, callBackFunction) {
        ApiRequest.request(
            mode,
            pathOrObj,
            (value) => callBackFunction(value),
            params
        );
    },
    getLoginAccount(accountId, callback) {
        this.request("GET", ACCOUNT_API_PATH + "/" + accountId, {}, callback);
    },
    getAccounts(companyIds, userName, userRole) {
        let params = {
            'filter[company]': companyIds,
            'filter[name]': userName,
            'filter[role]': userRole,
        }
        this.request("GET", ACCOUNT_API_PATH, params, this.handleNewAccounts);
    },
    handleNewAccounts(response) {
        Store.commit('updateAccounts', ApiRequest.deleteJV(response));
    },
    login(email, password, callback) {
        let params = {
            'username': email,
            'password': password
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        fetch(`${process.env.VUE_APP_APIURL}/${ACCOUNT_LOGIN_API_PATH}`, requestOptions)
            .then(this.handleResponse)
            .then(response => {
                localStorage.setItem('loginData', JSON.stringify(response));
                this.getLoginAccount(response.id, callback);
            }).catch(callback === null ? this.logout(null) : callback(""));
    },
    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            let isError = false;
            if (!response.ok) {
                isError = true;
                Store.commit('updatePasswordState', isError);
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            Store.commit('updatePasswordState', isError);
            return data;
        });
    },
    logout(callBackFunction) {
        localStorage.removeItem('loginData');
        Store.commit('updateLoginAccount', "");
        if (window.confApp.$router.history.current.name !== "Login") {
            window.confApp.$router.push({ name: "Login" });
        }
        if (callBackFunction !== null) {
            callBackFunction("");
        }
    }
};